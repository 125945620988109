<template>
  <div class="row py-3">
    <div class="col-6 pb-3">
      <button
        @click="handleClickBtnStart"
        class="btn btn-green btn-start"
        :class="price.status === 'live' ? 'active' : ''"
        :disabled="price.status === 'live' ? true : false"
      >
        เริ่มการแข่งขัน
      </button>
    </div>
    <div class="col-6 pb-3">
      <button @click="handleClickBtnEnd" class="btn btn-red">
        จบการแข่งขัน
      </button>
    </div>
    <div class="col-6 pb-3">
      <button @click="handleClickBtnWait" class="btn btn-white">
        ขึ้นรอสักครู่
      </button>
    </div>
  </div>
  <div class="setting-div">
    <button @click="handleClickTextBtn" class="btn btn-setting">
      <i class="fas fa-comment-dots"></i>
    </button>
    &nbsp;
    <button @click="handleClickBtnSetting" class="btn btn-setting">
      <i class="fas fa-cog"></i>
    </button>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { useIndex } from "./composable/use-control";

export default {
  name: "ControlIndex",
  setup() {
    const {
      state,
      price,
      handleClickBtnCreate,
      handleClickBtnSetting,
      handleClickBtnStart,
      handleClickBtnEnd,
      handleClickBtnWait,
      handleClickTextBtn,
    } = useIndex();
    return {
      ...toRefs(state),
      price,
      handleClickBtnCreate,
      handleClickBtnSetting,
      handleClickBtnStart,
      handleClickBtnEnd,
      handleClickBtnWait,
      handleClickTextBtn,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn {
  line-height: 2;
}
</style>
