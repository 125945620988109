import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { showLoggedin } from "@/views/match/composable/use-match";
import { login } from "@/services/auth-service";

export function useLogin() {
  const router = useRouter();
  const route = useRoute();
  const { isShowLoggedin } = showLoggedin();
  const state = reactive({
    userState: {
      username: "",
      password: "",
    },
    showPassword: false,
  });
  onMounted(() => {
    console.log(route.name);
    if (route.name === "Login") {
      localStorage.removeItem("uid");
    }
    calculateHeight();
  });
  const calculateHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  };
  const handleClickEye = () => {
    state.showPassword = !state.showPassword;
    const passwordInput = document.querySelector("#inputPassword");
    if (state.showPassword) {
      passwordInput.setAttribute("type", "text");
    } else {
      passwordInput.setAttribute("type", "password");
    }
  };
  const onSubmit = async () => {
    if (
      state.userState.username.trim() === "" ||
      state.userState.password.trim() === ""
    ) {
      return;
    }
    const data = {
      username: state.userState.username,
      password: state.userState.password,
      remember: false,
    };
    const response = await login(data);
    if (!response.status) {
      console.log("false");
    } else {
      localStorage.setItem("uid", response.data.id);
      await router.replace({ name: "Match" });
      isShowLoggedin();
      console.log("test");
    }
  };
  return {
    state,
    handleClickEye,
    onSubmit,
    calculateHeight,
  };
}
