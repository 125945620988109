<template>
  <div class="row py-3">
    <div v-if="priceInfo.result === 'wait'" class="col-6 pb-3">
      <button @click="handleClickBtnRedWin" class="btn btn-softred">
        แดงชนะ
      </button>
    </div>
    <div v-if="priceInfo.result === 'wait'" class="col-6 pb-3">
      <button @click="handleClickBtnBlueWin" class="btn btn-blue">
        น้ำเงินชนะ
      </button>
    </div>
    <div v-if="priceInfo.result === 'wait'" class="col-6 pb-3">
      <button @click="handleClickBtnDraw" class="btn btn-grey">เสมอ</button>
    </div>
    <div v-if="priceInfo.result === 'wait'" class="col-6 pb-3">
      <button @click="handleClickBtnCancle" class="btn btn-darkred">
        ยกเลิก
      </button>
    </div>
    <div v-if="priceInfo.result !== 'wait'" class="col-12">
      <div
        class="result text-center"
        :class="priceInfo.result !== 'wait' ? priceInfo.result : ''"
      >
        {{
          priceInfo.result === "home"
            ? "แดงชนะ"
            : priceInfo.result === "away"
            ? "น้ำเงินชนะ"
            : priceInfo.result === "draw"
            ? "เสมอ"
            : "ยกเลิก"
        }}
      </div>
    </div>
  </div>
  <div
    v-if="priceInfo.result !== 'wait' && price.can_new_game === true"
    class="row newgame-div"
  >
    <div class="col">
      <button
        class="btn btn-white"
        data-bs-toggle="modal"
        data-bs-target="#confirmEditModal"
      >
        <i class="fas fa-edit"></i>
        <span>แก้ไขผล</span>
      </button>
    </div>
    <div class="col">
      <button
        @click="handleClickNewGame"
        class="btn btn-white"
        data-bs-toggle="modal"
        data-bs-target="#NewModal"
      >
        <span>สร้างเกมใหม่</span>
      </button>
    </div>
  </div>
  <div class="setting-div">
    <button
      v-if="isLocal === 'true' && price.mode === 'pool'"
      class="btn btn-setting"
      data-bs-toggle="modal"
      data-bs-target="#poolModal"
    >
      <span>Pool+</span>
    </button>
    &nbsp;
    <button
      v-if="isLocal === 'true'"
      @click="handleClickReset"
      class="btn btn-setting"
    >
      <i class="fas fa-redo"></i><span>Reset</span>
    </button>
  </div>
  <Modal bgcolor="grey" id="confirmEditModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid text-center">
        <span class="semibold-20 text-black">ยืนยันการยกเลิกผล</span>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="btn btn-cancel"
        style="padding: 0 15px !important"
        data-bs-dismiss="modal"
      >
        ยกเลิก
      </button>
      <button
        type="button"
        class="btn btn-confirm"
        style="padding: 0 15px !important"
        data-bs-dismiss="modal"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
  <Modal bgcolor="grey" id="poolModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 px-1 text-center">
            <div class="bold-text">แดง</div>
            <div>
              <input
                v-model="poolAmount.red"
                class="form-control mt-2"
                type="text"
              />
            </div>
          </div>
          <div class="col-6 px-1 text-center">
            <div class="bold-text">น้ำเงิน</div>
            <div>
              <input
                v-model="poolAmount.blue"
                class="form-control mt-2"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        @click="handleClickSetPoolPrice"
        type="button"
        class="btn btn-confirm"
        data-bs-dismiss="modal"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
  <modal-new-game :league_id="price.league_id" />
</template>

<script>
import { toRefs } from "vue";
import { useResult } from "./composable/use-control";
import Modal from "@/components/Modal.vue";
import ModalNewGame from "../../components/modal/ModalNewGame.vue";

export default {
  name: "ControlResult",
  components: {
    Modal,
    ModalNewGame,
  },
  setup() {
    const {
      state,
      handleClickBtnRedWin,
      handleClickBtnBlueWin,
      handleClickBtnDraw,
      handleClickBtnCancle,
      handleClickReset,
      handleClickNewGame,
      handleClickSetPoolPrice,
    } = useResult();
    return {
      ...toRefs(state),
      handleClickBtnRedWin,
      handleClickBtnBlueWin,
      handleClickBtnDraw,
      handleClickBtnCancle,
      handleClickReset,
      handleClickNewGame,
      handleClickSetPoolPrice,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn {
  line-height: 3;
}
.result {
  font-family: "JamjureeBold";
  font-size: 1.875rem;
  line-height: 5;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  &.home {
    background-color: #ff5a5a;
  }
  &.away {
    background-color: #3271ff;
  }
  &.draw {
    background-color: #8e8e8e;
  }
  &.cancel {
    background-color: #830005;
  }
}
</style>
