import { computed, onMounted, reactive, toRefs, watch } from "vue";
import { swalConfirm1, swalConfirm2 } from "@/utilities/sweet-alert.js";
import {
  setGameStatus,
  setGamePrice,
  setGameResult,
  // setGamePriceOpen,
  setGameTicket,
  resetGame,
  setBetMin,
  setIsPause,
  setPool,
  setPinText,
} from "@/services/price-service";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import soundFile from "@/assets/sound/boxing-bell.mp3";
import { utilily } from "../../../utilities/utility";

export function useIndex() {
  const { swalCf1 } = swalConfirm1();
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  var price = computed(() => store.state.priceModule.price);
  const state = reactive({
    // price: computed(() => store.state.priceModule.price),
    swalOption: {
      buttonsStyling: false,
      showCancelButton: true,
      reverseButtons: true,
      html: "",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "<i class='fas fa-check-circle'></i> ยืนยัน",
      confirmButtonColor: "#ffffff",
      background: "#e5e5e5",
    },
  });
  const handleClickTextBtn = async () => {
    swalCf1
      .fire({
        input: "text",
        buttonsStyling: false,
        showCancelButton: true,
        showDenyButton: true,
        reverseButtons: true,
        html: "<span style='color:#000'>ใส่ข้อความขึ้นหน้าจอ</span>",
        cancelButtonText: "ยกเลิก",
        denyButtonText: `Clear`,
        confirmButtonText: "<i class='fas fa-check-circle'></i> ยืนยัน",
        confirmButtonColor: "#ffffff",
        background: "#c9c9c9",
      })
      .then(async (isConfirm) => {
        console.log(isConfirm);
        if (isConfirm.isConfirmed) {
          const data = { id: route.params.id, pin_text: isConfirm.value };
          const response = await setPinText(data);
          if (response.status) {
            console.log(response);
          }
        } else if (isConfirm.isDenied) {
          const data = { id: route.params.id, pin_text: null };
          const response = await setPinText(data);
          if (response.status) {
            console.log(response);
          }
        }
      });
  };
  const handleClickBtnSetting = async () => {
    const { value: text } = await swalCf1.fire({
      input: "tel",
      inputPlaceholder: "ราคาเล่นขั้นต่ำ",
      buttonsStyling: false,
      showCancelButton: true,
      reverseButtons: true,
      html: "<span style='color:#000'>ราคาเล่นขั้นต่ำ</span>",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "<i class='fas fa-check-circle'></i> ยืนยัน",
      confirmButtonColor: "#ffffff",
      background: "#c9c9c9",
    });

    if (text) {
      const data = { id: route.params.id, bet_min: +text * 100 };

      const response = await setBetMin(data);
      if (response.status) {
        console.log(text);
      }
    }
  };
  const handleClickBtnStart = () => {
    state.swalOption.html =
      "<span style='color:#000'>ยืนยันการเริ่มรายการสด</span>";
    swalCf1.fire(state.swalOption).then(async (isConfirm) => {
      if (isConfirm.isConfirmed) {
        const data = JSON.parse(`{"id":${route.params.id}, "status":"live"}`);
        const response = await setGameStatus(data);
        if (response.status) {
          price.value.status = "live";
          store.dispatch("priceModule/setPrice", price.value);
          console.log(price.value);
        }
      }
    });
  };
  const handleClickBtnEnd = () => {
    state.swalOption.html =
      "<span style='color:#000'>ยืนยันการจบรายการสด</span>";
    swalCf1.fire(state.swalOption).then(async (isConfirm) => {
      if (isConfirm.isConfirmed) {
        const data = JSON.parse(`{"id":${route.params.id}, "status":"end"}`);
        const response = await setGameStatus(data);
        if (response.status) {
          price.value.status = "end";
          store.dispatch("priceModule/setPrice", price.value);
          router.replace({
            name: "ControlResult",
          });
        }
      }
    });
  };
  const handleClickBtnWait = () => {
    state.swalOption.html =
      "<span style='color:#000'>ยืนยันการขึ้นข้อความ รอสักครู่</span>";
    swalCf1.fire(state.swalOption).then(async (isConfirm) => {
      if (isConfirm.isConfirmed) {
        const data = JSON.parse(`{"id":${route.params.id}, "status":"wait"}`);
        const response = await setGameStatus(data);
        if (response.status) {
          price.value.status = "wait";
          store.dispatch("priceModule/setPrice", price.value);
        }
      }
    });
  };
  const removeElementClass = (elem, name) => {
    elem.classList.remove(name);
  };
  const addElementClass = (elem, name) => {
    elem.classList.add(name);
  };
  const updateMainPage = async (route, store, force = false) => {
    if (window.lastUpdateMainPage === undefined) {
      window.lastUpdateMainPage = 0;
    }
    if (
      window.lastUpdateMainPage !== null &&
      (force === true || Date.now() - window.lastUpdateMainPage > 1000)
    ) {
      window.lastUpdateMainPage = null;
      const id = await route.params.id;
      try {
        await store.dispatch("priceModule/getPriceInfo", id);
      } catch (e) {
        console.error(e);
      } finally {
        window.lastUpdateMainPage = Date.now();
      }
    }
  };

  return {
    ...toRefs(state),
    price,
    addElementClass,
    removeElementClass,
    updateMainPage,
    handleClickBtnSetting,
    handleClickBtnStart,
    handleClickBtnEnd,
    handleClickBtnWait,
    handleClickTextBtn,
  };
}

export function usePrice() {
  const store = useStore();
  const route = useRoute();
  const { swalCf1 } = swalConfirm1();
  const { swalCf2 } = swalConfirm2();
  const state = reactive({
    showCalculateModal: false,
    selectPriceColor: "",
    selectPrice: computed(() => store.state.priceModule.selectPrice),
    roundPrice: computed(() => store.state.priceModule.roundPrice),
    price: computed(() => store.state.priceModule.price),
    priceInfo: computed(() => store.state.priceModule.priceInfo),
    priceType: computed(() => store.state.priceModule.priceType),
    isVideo: computed(() => store.state.priceModule.isVideo),
    priceHomeText: null,
    priceAwayText: null,
    canInputPrice: false,
    clearRed: false,
    clearBlue: false,
    isBargainDB: false,
    isBargainRedPass: false,
    isBargainBluePass: false,
    isToggleCalculator: true,
    bargainArr: [
      "A",
      "B",
      "C",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
    ],
    bargainRedString: "-",
    bargainBlueString: "-",
    isBargainRed: false,
    isBargainBlue: false,
    retainArr: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "00"],
    retainRedString: "-",
    retainBlueString: "-",
    retainHistory: [],
    priceHistory: "",
    oldRetain: "-",
    isRetainRed: false,
    isRetainBlue: false,
    isSetPrice: false,
    isSelectPriceType: "",
    isDigitpadOn: true,
    swalOption: {
      buttonsStyling: false,
      showCancelButton: true,
      reverseButtons: true,
      title: "",
      html: "",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "<i class='fas fa-check-circle'></i> ยืนยัน",
      confirmButtonColor: "#ffffff",
      background: "#ffdede",
    },
    swalOption2: {
      buttonsStyling: false,
      showCancelButton: true,
      reverseButtons: true,
      html: "",
      cancelButtonText: "<i class='fas fa-times-circle'></i> ยกเลิกบิลทั้งหมด",
      confirmButtonText: "<i class='fas fa-check-circle'></i> รับบิลทั้งหมด",
      confirmButtonColor: "#ffffff",
      background: "#e5e5e5",
    },
    swalOption3: {
      buttonsStyling: false,
      showCancelButton: true,
      showConfirmButton: false,
      html: "",
      cancelButtonText: "ปิด",
      background: "#ffdede",
    },
    roundIndex: null,
    timeLeft: 0,
    isTable: false,
    isSelectOnTable: false,
    isClosePrice: false,
    isMobile: false,
    approveTime: 0,
    isDBFound: false,
    isLocal: process.env.VUE_APP_LOCAL_DEV,

    audioSrc: soundFile,
  });
  onMounted(() => {
    var retainHistory = localStorage.getItem("retainHistory");
    if (retainHistory) {
      state.retainHistory = retainHistory.split(",");
    }
    if (screen.width <= 760) {
      state.isMobile = true;
    } else {
      state.isMobile = false;
    }
    getPriceHistory();
    checkPriceDelay();
    checkRound();
    setAutoApproveTime();
  });
  watch(
    () => [
      state.priceInfo.last_change_price,
      state.priceInfo.round_id,
      state.priceInfo.rank_price,
    ],
    (
      [lastChangePrice, roundId, rankPrice],
      [prevLastChangePrice, prevRoundId, prevRankPrice]
    ) => {
      if (lastChangePrice !== prevLastChangePrice) {
        checkPriceDelay();
        setAutoApproveTime();
      }
      if (roundId !== prevRoundId || rankPrice !== prevRankPrice) {
        store.dispatch("priceModule/setRoundPrice", {});
        state.isTable = false;
        checkRound();
      }
    }
  );
  const playSound = () => {
    const audio = new Audio(state.audioSrc);
    audio.play();
  };

  const handleClickVideo = (event) => {
    store.dispatch("priceModule/setIsVideo", event);
  };
  const setBlinkAnimation = (event) => {
    const elementIntv = setInterval(() => {
      const element = document.querySelector(".close-time");
      if (element !== null) {
        clearInterval(elementIntv);
        if (event === true) {
          element.classList.add("blink");
        } else {
          element.classList.remove("blink");
        }
      }
    }, 500);
  };
  const setAutoApproveTime = () => {
    if (
      state.priceInfo.auto_approve_time > 0 &&
      state.priceInfo.auto_approve_time !== null &&
      state.priceInfo.is_price_open === true &&
      state.priceInfo.last_change_price !== false &&
      state.priceInfo.last_change_price !== null
    ) {
      const dateNow = new Date();
      const approveTime =
        state.priceInfo.auto_approve_time +
        state.priceInfo.last_change_price -
        dateNow.getTime() / 1000;
      console.log("AUTO", approveTime);
      if (approveTime > 0) {
        setBlinkAnimation(false);
        clearInterval(window.approveTimeIntv);
        state.approveTime = parseInt(approveTime);

        window.approveTimeIntv = setInterval(() => {
          state.approveTime--;
          if (state.approveTime <= 0) {
            clearInterval(window.approveTimeIntv);
            state.approveTime = 0;
            // setTicket(false, true);
            setBlinkAnimation(true);
            console.log("BYE BYE");
          }
          if (
            state.approveTime <= 0 &&
            state.priceInfo.is_price_open === true
          ) {
            document.querySelector("#text-control").click();
            playSound();
          }
        }, 1000);
      } else {
        setBlinkAnimation(true);
        console.log("-2");
      }
    }
  };
  const setTimeLeft = () => {
    const dateNow = new Date();
    var checkTime =
      dateNow.getTime() / 1000 - state.priceInfo.last_change_price;
    // console.log(checkTime);
    if (
      state.price.update_price_delay !== null &&
      checkTime <= state.price.update_price_delay
    ) {
      clearInterval(window.priceDelayintv);
      state.timeLeft = state.price.update_price_delay - checkTime;
      window.priceDelayintv = setInterval(() => {
        // console.log(state.timeLeft);
        state.timeLeft = state.timeLeft - 1;
        if (state.timeLeft <= 0) {
          state.timeLeft = 0;
          clearInterval(window.priceDelayintv);
        }
      }, 1000);
    } else {
      return;
    }
  };
  const checkPriceDelay = () => {
    // state.price.update_price_delay;
    setTimeLeft();
    // if (Object.keys(state.price).length <= 0) {
    //   window.checkPriceintv = setInterval(() => {
    //     if (
    //       Object.keys(state.price).length > 0 &&
    //       Object.keys(state.priceInfo).length > 0
    //     ) {
    //       clearInterval(window.checkPriceintv);
    //       setTimeLeft();
    //     }
    //   }, 500);
    // }
  };
  const handleClickChangePrice = (side, name) => {
    console.log("suggest", side, name);
    if (side !== "") {
      if (name === undefined) {
        state.isDBFound = false;
        return;
      }
      if (name === "ตร") {
        handleClickBtnBar();
        return;
      }
      state.isSelectOnTable = true;
      if (side === "red") {
        state.isBargainBlue = false;
        state.isBargainRed = true;
      } else {
        state.isBargainBlue = true;
        state.isBargainRed = false;
      }
      state.selectPriceColor = side;
      state.roundIndex = state.roundPrice.round_prices.findIndex(
        (arr) => arr.name === name
      );
      // for (let index = 0; index <= n; index++) {
      //   state.roundIndex = state.roundPrice.round_prices.findIndex(
      //     (arr) => arr.name === priceName + setArr[index]
      //   );
      //   // console.log(n, index, priceName + setArr[index], state.roundIndex);
      //   if (state.roundIndex > 0) {
      //     break;
      //   }
      // }
      handleClickBtnBargainConfirm();
    } else {
      state.isSelectOnTable = false;
    }
  };
  const handleClickSetPause = async (event) => {
    const data = {
      id: parseInt(route.params.id),
      is_pause: event,
    };
    const response = await setIsPause(data);
    if (response.status) {
      console.log("Pause", event);
    }
  };
  const handleClickBtnUpper = () => {
    state.isClosePrice = false;
    state.isSelectOnTable = false;
    state.isSelectPriceType = "upper";
    state.isSetPrice = true;
    state.canInputPrice = true;
    toggleCalculateModal(true);
  };
  const handleClickBtnDraw = () => {
    state.isClosePrice = false;
    state.isSelectOnTable = false;
    state.isSelectPriceType = "draw";
    state.isSetPrice = true;
    state.canInputPrice = true;
    toggleCalculateModal(false);
  };
  const handleClickBtnBar = () => {
    state.isDBFound = true;
    if (state.isBargainDB) {
      state.isClosePrice = false;
      state.isSelectOnTable = false;
      state.isSelectPriceType = "bar";
      state.isSetPrice = true;
      state.canInputPrice = true;
      toggleCalculateModal(false);
      state.isBargainRedPass = true;
      state.isBargainBluePass = true;
      state.roundIndex = state.roundPrice.round_prices.findIndex(
        (arr) => arr.name === "ตร"
      );
      if (state.roundIndex < 0) {
        state.isDBFound = false;
        return;
      }

      state.bargainRedString = reverseString(
        state.roundPrice.round_prices[state.roundIndex].home_price
      );

      state.bargainBlueString =
        state.roundPrice.round_prices[state.roundIndex].away_price;
      state.retainRedString =
        state.roundPrice.round_prices[state.roundIndex].home_amount;
      state.retainBlueString =
        state.roundPrice.round_prices[state.roundIndex].away_amount;
    } else {
      state.isClosePrice = false;
      state.isSelectOnTable = false;
      state.isSelectPriceType = "bar";
      state.isSetPrice = true;
      state.canInputPrice = true;
      toggleCalculateModal(false);
      state.isBargainRedPass = true;
      state.isBargainBluePass = true;
      state.bargainRedString = "9/10";
      state.bargainBlueString = "9/10";
      setRetainHistory(state.bargainRedString, "red");
      setRetainHistory(state.bargainBlueString, "blue");
    }
  };

  const toggleCalculateModal = (boolean) => {
    state.showCalculateModal = boolean;
  };
  const checkRound = async () => {
    if (Object.keys(state.roundPrice).length === 0) {
      const data = {
        id: route.params.id,
        rank:
          state.priceInfo.rank_price !== null
            ? state.priceInfo.rank_price
            : state.price.rank_price,
      };
      await store.dispatch("priceModule/getRoundPrice", data);
    }
    if (
      state.roundPrice.round_prices.length > 0 &&
      state.roundPrice.round_id === state.priceInfo.round_id
    ) {
      state.isBargainDB = true;
      state.isToggleCalculator = false;
    } else {
      state.isBargainDB = false;
      state.isToggleCalculator = true;
    }
    console.log("DB", state.isBargainDB, state.isToggleCalculator);
  };
  const handleClickBtnRedPrice = async () => {
    if (!state.canInputPrice) {
      return;
    }
    // handleDigipadBtn("#digipadSlash", true);
    await checkRound();
    state.selectPriceColor = "red";
    state.isBargainRed = true;
    state.isBargainBlue = false;
    state.clearRed = true;
    state.clearBlue = false;
    state.bargainRedString = "-";
    state.bargainBlueString = "-";
    if (state.isBargainDB && state.isToggleCalculator === false) {
      checkRoundPrice("bargainRedString", "bargainBlueString");
      nextNumpad(state.bargainRedString);
    } else {
      enableAllBtnCalculate();
    }
    if (state.priceType === "bar") {
      state.bargainRedString = "10/9";
      state.bargainBlueString = "10/9";
    }
    if (state.isSelectPriceType === "draw") {
      state.isDBFound = true;
      if (state.isBargainDB === true) {
        state.roundIndex = state.roundPrice.round_prices.findIndex(
          (arr) => arr.name === "ส"
        );
        if (state.roundIndex < 0) {
          state.isDBFound = false;
          return;
        }

        state.bargainRedString =
          state.roundPrice.round_prices[state.roundIndex].home_price;
        state.bargainBlueString =
          state.roundPrice.round_prices[state.roundIndex].away_price;
        state.retainRedString =
          state.roundPrice.round_prices[state.roundIndex].home_amount;
        state.retainBlueString =
          state.roundPrice.round_prices[state.roundIndex].away_amount;
      } else {
        state.isBargainRedPass = true;
        state.isBargainBluePass = true;
        state.bargainRedString = "10/8";
        state.bargainBlueString = "10/10";
        setRetainHistory(reverseString(state.bargainRedString), "red");
        setRetainHistory(state.bargainBlueString, "blue");
      }
    }
  };
  const handleClickBtnBluePrice = async () => {
    if (!state.canInputPrice) {
      return;
    }
    // handleDigipadBtn("#digipadSlash", true);
    // for (let n = 0; n < state.bargainArr.length; n++) {
    //   handleDigipadBtn("#digipadBtn" + state.bargainArr[n], true);
    // }
    await checkRound();
    state.selectPriceColor = "blue";
    state.isBargainRed = false;
    state.isBargainBlue = true;
    state.clearRed = false;
    state.clearBlue = true;
    state.bargainRedString = "-";
    state.bargainBlueString = "-";
    if (state.isBargainDB && state.isToggleCalculator === false) {
      checkRoundPrice("bargainBlueString", "bargainRedString");
      nextNumpad(state.bargainBlueString);
    } else {
      enableAllBtnCalculate();
    }
    if (state.priceType === "bar") {
      state.bargainRedString = "10/9";
      state.bargainBlueString = "10/9";
      addStringBargain;
    }
    if (state.isSelectPriceType === "draw") {
      state.isDBFound = true;
      if (state.isBargainDB === true) {
        state.roundIndex = state.roundPrice.round_prices.findIndex(
          (arr) => arr.name === "ส"
        );
        if (state.roundIndex < 0) {
          state.isDBFound = false;
          return;
        }

        state.bargainRedString =
          state.roundPrice.round_prices[state.roundIndex].away_price;
        state.bargainBlueString =
          state.roundPrice.round_prices[state.roundIndex].home_price;
        state.retainRedString =
          state.roundPrice.round_prices[state.roundIndex].away_amount;
        state.retainBlueString =
          state.roundPrice.round_prices[state.roundIndex].home_amount;
      } else {
        state.isBargainRedPass = true;
        state.isBargainBluePass = true;
        state.bargainRedString = "10/10";
        state.bargainBlueString = "10/8";
        setRetainHistory(state.bargainRedString, "red");
        setRetainHistory(reverseString(state.bargainBlueString), "blue");
      }
    }
  };
  const handleClickTable = async () => {
    state.isClosePrice = false;
    state.isTable = !state.isTable;
    await checkRound();
  };
  // const handleSelectPrice = () => {
  //   if (state.selectPrice.side === "red") {
  //     state.isBargainBlue = false;
  //     state.isBargainRed = true;
  //   } else {
  //     state.isBargainBlue = true;
  //     state.isBargainRed = false;
  //   }
  //   state.selectPriceColor = state.selectPrice.side;
  //   state.roundIndex = state.roundPrice.round_prices.findIndex(
  //     (arr) => arr.name === state.selectPrice.price.toLowerCase()
  //   );
  //   handleClickBtnBargainConfirm();
  // };
  const autoSlashString = (string) => {
    var leftString, rightString, leftNum, rightNum, returnText;
    for (let index = 1; index <= string.length; index++) {
      returnText = [];

      var inputText = string.replace(/\.5/g, "");
      var match = string.match(/\.5/g);
      leftString = inputText.slice(0, index);
      rightString = inputText.slice(index);
      leftNum = parseFloat(leftString);
      rightNum = parseFloat(rightString);
      console.log(leftString, rightString, leftNum, rightNum, inputText, match);
      if (leftNum === 0) {
        return "-";
      } else if (isNaN(rightNum)) {
        if (match !== null) {
          return leftString + match[0] + "/";
        } else {
          return leftString + "/";
        }
      } else if (rightNum === 0) {
        return `${leftString}${rightString}/`;
      } else if (leftNum >= rightNum) {
        if (rightString.substring(0, 1) === "0") {
          continue;
        } else {
          if (match !== null) {
            if (match.length > 1) {
              returnText[0] = leftString + match[0];
              returnText[1] = rightString + match[1];
            } else {
              if (string.indexOf(".5") === string.length - 2) {
                if (leftNum > rightNum) {
                  returnText[0] = leftString;
                  returnText[1] = rightString + match[0];
                } else {
                  returnText[0] = leftString;
                  returnText[1] = rightString;
                }
              } else {
                returnText[0] = leftString + match[0];
                returnText[1] = rightString;
              }
            }
          } else {
            returnText[0] = leftString;
            returnText[1] = rightString;
          }
          return returnText.join("/");
        }
      }
    }
    return "-";
  };
  const checkRemoveHalfOne = (text) => {
    var match = text.match(/\.5/g);
    console.log("del", text.length - 2);
    if (
      text.indexOf(
        ".5",
        match !== null && match.length > 1 ? text.length - 2 : 0
      ) ===
      text.length - 2
    ) {
      text = text.slice(0, -2);
    } else {
      text = text.slice(0, -1);
    }
    return text;
  };
  const addStringBargainDB = (string, number) => {
    var inputText;
    if (number === "del") {
      inputText = string;
      inputText = checkRemoveHalfOne(inputText);
      if (inputText.length > 0) {
        string = inputText;
      } else {
        string = "-";
      }
    } else {
      if (state.clearRed || state.clearBlue) {
        string = "-";
      }
      if (string === "-") {
        string = "";
      }
      string += number;
    }
    state.clearRed = false;
    state.clearBlue = false;
    return string;
  };
  const addStringBargain = (string, number) => {
    var inputText;
    if (number === "del") {
      inputText = string.replace(/\//g, "");
      inputText = checkRemoveHalfOne(inputText);
      if (inputText.length <= 0) {
        string = "-";
      } else {
        string = autoSlashString(inputText);
      }
    } else {
      if (state.clearRed || state.clearBlue) {
        string = "";
      }
      if (string === "-") {
        string = "";
      }
      if (string.indexOf(".5") !== string.length - 2) {
        string += number;
        inputText = string.replace(/\//g, "");
        string = autoSlashString(inputText);
      }
    }
    state.clearRed = false;
    state.clearBlue = false;
    return string;
  };
  const addStringRetain = (string, number, replace) => {
    if (replace) {
      string = "-";
    }
    if (number === "del") {
      string = string.slice(0, -1);
      if (string.length <= 0) {
        string = "-";
      }
    } else {
      if (string === "-") {
        string = "";
      }
      string += number;
    }
    return string;
  };
  const reverseString = (string) => {
    return string.split("/").reverse().join("/");
  };
  const checkPrice = (string1) => {
    // console.log(state.isSelectPriceType);
    const Arr1 = string1.split("/");
    // const Arr2 = string2.split("/");
    // if (Arr1[1] / Arr1[0] > 1 && Arr2[1] / Arr2[0] > 1) {
    //   return reverseString(string1);
    // }
    if (state.priceType !== "upper") {
      if (Arr1[1] / Arr1[0] < 1) {
        return string1;
      } else {
        return reverseString(string1);
      }
    }
    if (Arr1[1] / Arr1[0] <= 1) {
      // ต่อ
      return `ร${string1}`;
    } else {
      // รอง
      return `ต${reverseString(string1)}`;
    }
  };
  const handleClickBtnBargainCancel = () => {
    state.isSelectPriceType = "";
    // state.bargainRedString = "-";
    // state.bargainBlueString = "-";
  };
  const handleClickBtnRetainCancel = () => {
    if (state.isRetainRed) {
      state.retainRedString = state.oldRetain;
    } else if (state.isRetainBlue) {
      state.retainBlueString = state.oldRetain;
    }
  };
  const handleClickBtnConfirmCancel = () => {
    // state.isBargainRedPass = false;
    // state.isBargainBluePass = false;
    // state.bargainRedString = "-";
    // state.bargainBlueString = "-";
    if (state.isBargainDB && state.isToggleCalculator === false) {
      if (state.isBargainRed) {
        checkRoundPrice("bargainRedString", "bargainBlueString");
        nextNumpad(state.bargainRedString);
      } else if (state.isBargainBlue) {
        checkRoundPrice("bargainBlueString", "bargainRedString");
        nextNumpad(state.bargainBlueString);
      }
    }
  };
  const enableAllBtnCalculate = () => {
    handleDigipadBtn("#digipadSlash", true);
    handleDigipadBtn("#digipadBtn00", true);
    handleDigipadBtn("#digipadBtnHalf", true);
    for (let n = 0; n < state.bargainArr.length; n++) {
      handleDigipadBtn("#digipadBtn" + state.bargainArr[n], true);
    }
  };
  const handleClickCalculator = () => {
    state.isToggleCalculator = !state.isToggleCalculator;
    state.bargainRedString = "-";
    state.bargainBlueString = "-";
    if (state.isBargainDB && state.isToggleCalculator === false) {
      if (state.isBargainRed) {
        checkRoundPrice("bargainRedString", "bargainBlueString");
        nextNumpad(state.bargainRedString);
      } else if (state.isBargainBlue) {
        checkRoundPrice("bargainBlueString", "bargainRedString");
        nextNumpad(state.bargainBlueString);
      }
    } else {
      enableAllBtnCalculate();
    }
  };
  const handleClickBtnBargainConfirm = () => {
    if (
      state.isBargainDB &&
      (state.isToggleCalculator === false || state.isSelectOnTable === true)
    ) {
      if (state.isBargainRed) {
        state.bargainRedString =
          state.roundPrice.round_prices[state.roundIndex].home_price;
        state.bargainBlueString =
          state.roundPrice.round_prices[state.roundIndex].away_price;
        state.retainRedString =
          state.roundPrice.round_prices[state.roundIndex].home_amount;
        state.retainBlueString =
          state.roundPrice.round_prices[state.roundIndex].away_amount;
      } else {
        state.bargainRedString =
          state.roundPrice.round_prices[state.roundIndex].away_price;
        state.bargainBlueString =
          state.roundPrice.round_prices[state.roundIndex].home_price;
        state.retainRedString =
          state.roundPrice.round_prices[state.roundIndex].away_amount;
        state.retainBlueString =
          state.roundPrice.round_prices[state.roundIndex].home_amount;
      }
    } else {
      state.retainRedString = "-";
      state.retainBlueString = "-";
      if (state.selectPriceColor === "red") {
        setRetainHistory(reverseString(state.bargainRedString), "red");
        setRetainHistory(state.bargainBlueString, "blue");
      } else {
        setRetainHistory(state.bargainRedString, "red");
        setRetainHistory(reverseString(state.bargainBlueString), "blue");
      }
    }
  };
  const handleClickBtnShowBargain = (color) => {
    if (color === "red") {
      if (state.isBargainRedPass === false) {
        state.bargainRedString = "-";
      }
      state.isBargainBlue = false;
      state.isBargainRed = true;
      state.clearRed = true;
      state.clearBlue = false;
    } else {
      if (state.isBargainBluePass === false) {
        state.bargainBlueString = "-";
      }
      state.isBargainBlue = true;
      state.isBargainRed = false;
      state.clearRed = false;
      state.clearBlue = true;
    }
  };
  const handleClickBtnShowRetain = (color) => {
    if (color === "red") {
      state.oldRetain = state.retainRedString;
      state.isRetainBlue = false;
      state.isRetainRed = true;
    } else {
      state.oldRetain = state.retainBlueString;
      state.isRetainBlue = true;
      state.isRetainRed = false;
    }
  };
  const handleClickRetainNumpad = (number, replace = false) => {
    if (!state.isRetainRed && !state.isRetainBlue) {
      return;
    }
    if (state.isRetainRed) {
      state.retainRedString = addStringRetain(
        state.retainRedString,
        number,
        replace
      );
    } else if (state.isRetainBlue) {
      state.retainBlueString = addStringRetain(
        state.retainBlueString,
        number,
        replace
      );
    }
  };
  const checkRoundPrice = (side1, side2) => {
    if (Object.keys(state.roundPrice).length === 0) {
      return;
    }
    state.roundIndex = state.roundPrice.round_prices.findIndex(
      (arr) => arr.name === state[side1].toLowerCase()
    );
    if (
      state.roundIndex >= 0 &&
      state.roundPrice.round_id === state.priceInfo.round_id
    ) {
      state.isBargainRedPass = true;
      state.isBargainBluePass = true;
      state[side2] = state.roundPrice.round_prices[state.roundIndex].away_price;
    } else {
      state.isBargainRedPass = false;
      state.isBargainBluePass = false;
      state[side2] = "-";
    }
    // console.log(state.isBargainRedPass, state.isBargainBluePass);
  };
  const checkNextnumpad = (string, character) => {
    let checkString = string + character.toLowerCase();
    let found = false;
    for (let index = 0; index < state.roundPrice.round_prices.length; index++) {
      const regex = new RegExp(checkString, "g");
      var result;
      result = state.roundPrice.round_prices[index].name.search(regex);
      // console.log(result, state.roundPrice.round_prices[index].name);
      if (result === 0) {
        // console.log(result, state.roundPrice.round_prices[index].name);
        found = true;
        break;
      }
    }
    // console.log("found", found, checkString);
    return found;
  };
  const handleDigipadBtn = (id, enable) => {
    const btn = document.querySelector(id);
    if (btn) {
      if (enable === true) {
        btn.removeAttribute("disabled");
      } else {
        btn.setAttribute("disabled", true);
      }
    }
  };
  const nextNumpad = (bargainString) => {
    // var stringPrice = bargainString.replace(/\//g, "");
    var stringPrice = bargainString !== "-" ? bargainString : "";
    const foundSlash = checkNextnumpad(stringPrice, "/");
    handleDigipadBtn("#digipadSlash", foundSlash);
    const found00 = checkNextnumpad(stringPrice, "00");
    handleDigipadBtn("#digipadBtn00", found00);
    const foundHalf = checkNextnumpad(stringPrice, "\\.5");
    handleDigipadBtn("#digipadBtnHalf", foundHalf);
    for (let n = 0; n < state.bargainArr.length; n++) {
      const found = checkNextnumpad(stringPrice, state.bargainArr[n]);
      handleDigipadBtn("#digipadBtn" + state.bargainArr[n], found);
    }
  };
  const handleClickBargainNumpad = (number) => {
    if (!state.isBargainRed && !state.isBargainBlue) {
      return;
    }
    if (state.isBargainDB === true && state.isToggleCalculator === false) {
      if (state.isBargainRed) {
        state.bargainRedString = addStringBargainDB(
          state.bargainRedString,
          number
        );
        checkRoundPrice("bargainRedString", "bargainBlueString");
        nextNumpad(state.bargainRedString);
      } else if (state.isBargainBlue) {
        state.bargainBlueString = addStringBargainDB(
          state.bargainBlueString,
          number
        );
        checkRoundPrice("bargainBlueString", "bargainRedString");
        nextNumpad(state.bargainBlueString);
      }
    } else {
      if (state.isBargainRed) {
        state.bargainRedString = addStringBargain(
          state.bargainRedString,
          number
        );
        const checkString = state.bargainRedString.split("/");
        if (checkString[1]) {
          state.isBargainRedPass = true;
        } else {
          state.isBargainRedPass = false;
        }
      } else if (state.isBargainBlue) {
        state.bargainBlueString = addStringBargain(
          state.bargainBlueString,
          number
        );
        const checkString = state.bargainBlueString.split("/");
        if (checkString[1]) {
          state.isBargainBluePass = true;
        } else {
          state.isBargainBluePass = false;
        }
      }
    }
  };
  const getPriceHistory = () => {
    var priceHistory = localStorage.getItem("priceHistory");
    if (priceHistory) {
      // console.log(priceHistory);
      state.priceHistory = JSON.parse(priceHistory);
    }
  };
  const regExPriceHistory = (price, value = null) => {
    var priceSplit = price.split("/");

    var stringToTheRegex = "(" + priceSplit[0] + "/" + priceSplit[1] + ")";
    var regex = new RegExp(stringToTheRegex + "=>([0-9]+),", "i");

    getPriceHistory();
    let text = state.priceHistory;
    // var reg = /(10\/8)(=>)(\d+)([,])/i;
    var info = text.match(regex);
    // console.log(info);
    if (value) {
      var add;
      if (info === null) {
        add = text + `${priceSplit[0]}/${priceSplit[1]}=>${value},`;
      } else {
        add = text.replace(
          regex,
          `${priceSplit[0]}/${priceSplit[1]}=>${value},`
        );
      }
      // console.log(add);
      localStorage.setItem("priceHistory", JSON.stringify(add));
    }
    return info;
  };
  const setRetainHistory = (price, side) => {
    console.log(price);
    var info = regExPriceHistory(price);
    if (info) {
      console.log(side);
      if (side === "red") {
        state.retainRedString = info[2];
      } else {
        state.retainBlueString = info[2];
      }
    } else {
      if (side === "red") {
        state.retainRedString = "-";
      } else {
        state.retainBlueString = "-";
      }
    }
  };
  const addHistory = (value) => {
    if (!state.retainHistory.includes(value)) {
      state.retainHistory.unshift(value);
    }
    if (state.retainHistory.length > 6) {
      for (let index = 0; index < state.retainHistory.length; index++) {
        if (index > 5) {
          state.retainHistory.pop();
        }
      }
    }
    localStorage.setItem("retainHistory", state.retainHistory);
  };
  const addBargainToPriceObj = async (
    bargainRed,
    bargainBlue,
    retainRed,
    retainBlue
  ) => {
    var data = `
      "id":${route.params.id}, 
      "home_price":"${bargainRed}", 
      "away_price":"${bargainBlue}", 
      "home_price_limit":${+retainRed * 100}, 
      "away_price_limit":${+retainBlue * 100},
      "ticket_delay_time": ${
        state.isBargainDB === true && state.isSelectOnTable === true
          ? state.roundPrice.round_prices[state.roundIndex].ticket_delay_time
          : null
      }
    `;
    console.log(data);
    const response = await setGamePrice(JSON.parse(`{${data}}`));
    if (response.status) {
      addHistory(retainRed);
      regExPriceHistory(bargainRed, retainRed);
      addHistory(retainBlue);
      regExPriceHistory(bargainBlue, retainBlue);
      state.isBargainRed = false;
      state.isBargainBlue = false;
      state.isRetainRed = false;
      state.isRetainBlue = false;
      useIndex().updateMainPage(route, store, true);
      state.isSelectPriceType = "";
      state.isSelectOnTable = false;
      store.dispatch("priceModule/setSelectPrice", {});
      checkPriceDelay();
    }
  };
  const handleClickSubmitPrice = async () => {
    if (state.retainRedString === "-" || state.retainBlueString === "-") {
      state.swalOption3.html =
        "<span style='color:#000'>กรุณากรอกราคาอั้นแดงและน้ำเงิน</span>";
      state.swalOption3.background = "#ffdede";
      swalCf1.fire(state.swalOption3);
      return;
    }

    if (state.isBargainDB === true) {
      if (state.roundPrice.round_id !== state.priceInfo.round_id) {
        state.swalOption3.html =
          "<span style='color:#000'>มีการเปลี่ยนยกเกิดขึ้นกรุณากรอกราคาใหม่</span>";
        state.swalOption3.background = "#ffdede";
        swalCf1.fire(state.swalOption3);
        return;
      }
    }
    if (state.isSelectPriceType === "bar") {
      addBargainToPriceObj(
        state.bargainRedString,
        state.bargainBlueString,
        state.retainRedString,
        state.retainBlueString
      );
    } else {
      if (state.selectPriceColor === "red") {
        addBargainToPriceObj(
          reverseString(state.bargainRedString),
          state.bargainBlueString,
          state.retainRedString,
          state.retainBlueString
        );
      } else {
        addBargainToPriceObj(
          state.bargainRedString,
          reverseString(state.bargainBlueString),
          state.retainRedString,
          state.retainBlueString
        );
      }
    }
  };
  const handleClickConfirmDelay = () => {
    if (state.isClosePrice === true) {
      handleClickBtnClosePrice();
    }
  };
  const setTicket = async (priceOpen, clearTicket) => {
    // const data = JSON.parse(
    //   `{"id":${route.params.id}, "is_price_open":${priceOpen}, "clear_ticket":${clearTicket}}`
    // );
    const data = {
      id: parseInt(route.params.id),
      is_price_open: priceOpen,
      clear_ticket: clearTicket,
    };
    console.log(data);
    const response = await setGameTicket(data);
    if (response.status) {
      useIndex().updateMainPage(route, store, true);
      if (clearTicket) {
        state.swalOption3.html = `<span style='color:#fff'>
          รับบิล ${response.data.total_clear_ticket} บิลเรียบร้อย
        </span>`;
        state.swalOption3.background = "#20cb03";
      } else {
        state.swalOption3.html = `<span style='color:#fff'>
          ยกเลิก ${response.data.total_clear_ticket} บิลเรียบร้อย
        </span>`;
        state.swalOption3.background = "#ff7070";
      }
      swalCf1.fire(state.swalOption3);
      console.log("pass");
    }
  };
  const handleClickBtnClosePrice = (timeLeft = false) => {
    if (timeLeft === true) {
      state.isSelectOnTable = false;
      state.isClosePrice = true;
    } else {
      state.isClosePrice = false;
      state.swalOption2.html =
        "<span style='color:#000'>ยืนยันการปิดราคา</span>";
      swalCf2.fire(state.swalOption2).then((isConfirm) => {
        console.log(isConfirm);
        if (isConfirm.isConfirmed) {
          setTicket(false, true);
        } else if (isConfirm.isDismissed && isConfirm.dismiss === "cancel") {
          setTicket(false, false);
        }
      });
    }
  };
  const handleClickBtnGetBill = () => {
    state.swalOption2.html = "<span style='color:#000'>ยืนยันการรับบิล</span>";
    swalCf2.fire(state.swalOption2).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        setTicket(true, true);
      } else if (isConfirm.isDismissed && isConfirm.dismiss === "cancel") {
        setTicket(true, false);
      }
    });
  };
  return {
    state,
    toggleCalculateModal,
    handleClickBtnUpper,
    handleClickBtnDraw,
    handleClickBtnBar,
    handleClickBtnRedPrice,
    handleClickBtnBluePrice,
    handleClickBtnBargainCancel,
    handleClickBtnBargainConfirm,
    checkPrice,
    handleClickBtnShowBargain,
    handleClickSubmitPrice,
    handleClickBargainNumpad,
    handleClickBtnConfirmCancel,
    handleClickRetainNumpad,
    handleClickBtnShowRetain,
    handleClickBtnRetainCancel,
    addBargainToPriceObj,
    handleClickBtnClosePrice,
    handleClickBtnGetBill,
    handleClickTable,
    handleClickChangePrice,
    handleClickSetPause,
    handleClickConfirmDelay,
    handleClickCalculator,
    handleClickVideo,
  };
}

export function useResult() {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const { swalCf1 } = swalConfirm1();
  const { handleClickCreateNewGame } = utilily();
  const state = reactive({
    price: computed(() => store.state.priceModule.price),
    priceInfo: computed(() => store.state.priceModule.priceInfo),
    isLocal: process.env.VUE_APP_LOCAL_DEV,
    nameLists: computed(() => store.state.priceModule.nameLists),
    poolAmount: {
      red: "",
      blue: "",
    },
    swalOption: {
      buttonsStyling: false,
      showCancelButton: true,
      reverseButtons: true,
      html: "",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "<i class='fas fa-check-circle'></i> ยืนยัน",
      confirmButtonColor: "#ffffff",
      background: "#e5e5e5",
    },
    swalOption3: {
      buttonsStyling: false,
      showCancelButton: true,
      showConfirmButton: false,
      html: "",
      cancelButtonText: "ปิด",
      background: "#ff7070",
    },
  });
  const handleClickBtnRedWin = () => {
    state.swalOption.html = "<span style='color:#000'>เลือก แดง ชนะ</span>";
    swalCf1.fire(state.swalOption).then(async (isConfirm) => {
      if (isConfirm.isConfirmed) {
        state.swalOption.html =
          "<div class='swal-confirm2' style='background-color: #FF1720;'>ยืนยันใส่ผล แดง ชนะ</div>";
        swalCf1.fire(state.swalOption).then(async (isConfirm) => {
          if (isConfirm.isConfirmed) {
            const data = JSON.parse(
              `{"id":${route.params.id}, "result":"home"}`
            );
            const response = await setGameResult(data);
            console.log(response);
            if (response.status) {
              state.price.result = "home";
              store.dispatch("priceModule/setPrice", state.price);
              state.swalOption3.html = `<span style='color:#fff'><i class="fas fa-check-circle"></i> เลือกแดงชนะเรียบร้อย</span>`;
              state.swalOption3.background = "#20cb03";
              swalCf1.fire(state.swalOption3);
            } else {
              state.swalOption3.html = `<span style='color:#fff'>${response.message}</span>`;
              swalCf1.fire(state.swalOption3);
            }
          }
        });
      }
    });
  };
  const handleClickBtnBlueWin = () => {
    state.swalOption.html = "<span style='color:#000'>เลือก น้ำเงิน ชนะ</span>";
    swalCf1.fire(state.swalOption).then(async (isConfirm) => {
      if (isConfirm.isConfirmed) {
        state.swalOption.html =
          "<div class='swal-confirm2' style='background-color: #1300E8;'>ยืนยันใส่ผล น้ำเงิน ชนะ</div>";
        swalCf1.fire(state.swalOption).then(async (isConfirm) => {
          if (isConfirm.isConfirmed) {
            const data = JSON.parse(
              `{"id":${route.params.id}, "result":"away"}`
            );
            const response = await setGameResult(data);
            if (response.status) {
              state.price.result = "away";
              store.dispatch("priceModule/setPrice", state.price);
              state.swalOption3.html = `<span style='color:#fff'><i class="fas fa-check-circle"></i> เลือกน้ำเงินชนะเรียบร้อย</span>`;
              state.swalOption3.background = "#20cb03";
              swalCf1.fire(state.swalOption3);
            } else {
              state.swalOption3.html = `<span style='color:#fff'>${response.message}</span>`;
              swalCf1.fire(state.swalOption3);
            }
          }
        });
      }
    });
  };
  const handleClickBtnDraw = () => {
    state.swalOption.html = "<span style='color:#000'>เลือก เสมอ</span>";
    swalCf1.fire(state.swalOption).then(async (isConfirm) => {
      if (isConfirm.isConfirmed) {
        state.swalOption.html =
          "<div class='swal-confirm2' style='background-color: #575757;'>ยืนยันใส่ผล เสมอ</div>";
        swalCf1.fire(state.swalOption).then(async (isConfirm) => {
          if (isConfirm.isConfirmed) {
            const data = JSON.parse(
              `{"id":${route.params.id}, "result":"draw"}`
            );
            const response = await setGameResult(data);
            if (response.status) {
              state.price.result = "draw";
              store.dispatch("priceModule/setPrice", state.price);
              state.swalOption3.html = `<span style='color:#fff'><i class="fas fa-check-circle"></i> เลือกเสมอเรียบร้อย</span>`;
              state.swalOption3.background = "#20cb03";
              swalCf1.fire(state.swalOption3);
            } else {
              state.swalOption3.html = `<span style='color:#fff'>${response.message}</span>`;
              swalCf1.fire(state.swalOption3);
            }
          }
        });
      }
    });
  };
  const handleClickBtnCancle = () => {
    state.swalOption.html = "<span style='color:#000'>เลือก ยกเลิก</span>";
    swalCf1.fire(state.swalOption).then(async (isConfirm) => {
      if (isConfirm.isConfirmed) {
        state.swalOption.html =
          "<div class='swal-confirm2' style='background-color: #830005;'>ยืนยันใส่ผล ยกเลิก</div>";
        swalCf1.fire(state.swalOption).then(async (isConfirm) => {
          if (isConfirm.isConfirmed) {
            const data = JSON.parse(
              `{"id":${route.params.id}, "result":"cancel"}`
            );
            const response = await setGameResult(data);
            if (response.status) {
              state.price.result = "cancel";
              store.dispatch("priceModule/setPrice", state.price);
              state.swalOption3.html = `<span style='color:#fff'><i class="fas fa-check-circle"></i> เลือกยกเลิกเรียบร้อย</span>`;
              state.swalOption3.background = "#20cb03";
              swalCf1.fire(state.swalOption3);
            } else {
              state.swalOption3.html = `<span style='color:#fff'>${response.message}</span>`;
              swalCf1.fire(state.swalOption3);
            }
          }
        });
      }
    });
  };
  const handleClickSetPoolPrice = async () => {
    const data = {
      league_id: state.price.league_id,
      video_id: state.price.additional.match_video_id,
      home: state.poolAmount.red * 100,
      away: state.poolAmount.blue * 100,
    };
    console.log(data);
    const response = await setPool(data);
    console.log(response);
  };
  const handleClickReset = async () => {
    await resetGame(route.params.id);
    await router.replace({
      name: "Match",
    });
  };
  const handleClickNewGame = async () => {
    handleClickCreateNewGame(state.price.league_id);
  };
  return {
    state,
    handleClickBtnRedWin,
    handleClickBtnBlueWin,
    handleClickBtnDraw,
    handleClickBtnCancle,
    handleClickReset,
    handleClickNewGame,
    handleClickSetPoolPrice,
  };
}
