import { request } from "./api";

export function gameInit(data) {
  const url = `/streamer/game-init`;
  return request("post", url, data);
}

export function getGamePrice(data) {
  const url = `/streamer/game-price/${data}`;
  return request("get", url, {});
}

export function setGameStatus(data) {
  const url = `/streamer/setgamestatus`;
  return request("post", url, data);
}

export function setGamePrice(data) {
  const url = `/streamer/setprice`;
  return request("post", url, data);
}

export function setRank(data) {
  const url = `/streamer/setrank`;
  return request("post", url, data);
}

export function getGamePriceInfo(data) {
  const url = `/streamer/priceinfo`;
  return request("post", url, { id: data });
}

export function getGamePriceSum(data) {
  const url = `/streamer/totalbet`;
  return request("post", url, data);
}

export function setGamePriceOpen(data) {
  const url = `/streamer/setpriceopen`;
  return request("post", url, data);
}

export function setGameTicket(data) {
  const url = `/streamer/manageticket`;
  return request("post", url, data);
}

export function setGameRound(data) {
  const url = `/streamer/setround`;
  return request("post", url, data);
}

export function setGameResult(data) {
  const url = `/streamer/setresult`;
  return request("post", url, data);
}

export function setBetMin(data) {
  const url = `streamer/setbetmin`;
  return request("post", url, data);
}

export function resetGame(data) {
  const url = `/streamer/reset-game-price/${data}`;
  return request("get", url, {});
}

export function getLiveToken(league_id, game_price_id) {
  const url = `/streamer/livetoken`;
  return request("post", url, {
    league_id: parseInt(league_id),
    game_price_id: parseInt(game_price_id),
  });
}

export function getRoundPrice(data) {
  const url = `/streamer/roundprices`;
  return request("post", url, data);
}

export function setIsPause(data) {
  const url = `/streamer/setispause`;
  return request("post", url, data);
}

export function setNewGame(data) {
  const url = `/streamer/newGame`;
  return request("post", url, data);
}

export function setMatchVideo(data) {
  const url = `/streamer/setMatchVideo`;
  return request("post", url, data);
}

export function setPool(data) {
  const url = `/streamer/setPool`;
  return request("post", url, data);
}

export function setPinText(data) {
  const url = `/streamer/setpintext`;
  return request("post", url, data);
}

export function getPlaybackList(data) {
  const url = `/streamer/playbackgamelist/${data}`;
  return request("get", url, {});
}

export function getPlaybackURL(data) {
  const url = `/streamer/playbackurl/${data}`;
  return request("get", url, {});
}

export function getNameLists(key) {
  const url = `/streamer/cache/${key}`;
  return request("get", url, {});
}

export function putNameLists(key, data) {
  const url = `/streamer/cache/${key}`;
  return request("put", url, data);
}

export function getTeamList(id) {
  const url = `/streamer/team-list/${id}`;
  return request("get", url, {});
}

export function getGameStat(id) {
  const url = `/streamer/game-stats/${id}`;
  return request("get", url, {});
}
