<template>
  <div id="header" class="row bg-black lh-lg shadow">
    <div class="col-1 header-angle">
      <router-link @click.prevent="handleClickBack" :to="{}"
        ><i class="fas fa-angle-left"></i
      ></router-link>
    </div>
    <div v-if="price" class="col align-self-center header-text">
      <LiveIcon v-if="price.status === 'live'" id="lavContainer" />
      <span
        >{{ price.league_name }} คู่ที่ {{ price.position }} #{{
          price.id
        }}</span
      >
    </div>
    <div v-else @click="$emit('handleClickRefresh')" class="col refresh-icon">
      <i class="fas fa-redo-alt"></i>
    </div>
  </div>
</template>

<script>
import LiveIcon from "@/components/LiveIcon.vue";
import { useRouter } from "vue-router";
import { logout } from "@/services/auth-service";
import { swalConfirm1 } from "@/utilities/sweet-alert.js";

export default {
  name: "Header",
  components: {
    LiveIcon,
  },
  props: ["price", "back"],
  setup(props) {
    const router = useRouter();
    const { swalCf1 } = swalConfirm1();
    const handleClickBack = async () => {
      if (props.back !== "Login") {
        router.replace({ name: props.back });
      } else {
        swalCf1
          .fire({
            buttonsStyling: false,
            showCancelButton: true,
            reverseButtons: true,
            html: "ยืนยันการออกจากระบบ",
            cancelButtonText: "ยกเลิก",
            confirmButtonText: "<i class='fas fa-check-circle'></i> ยืนยัน",
            confirmButtonColor: "#ffffff",
            background: "#e5e5e5",
          })
          .then(async (isConfirm) => {
            if (isConfirm.isConfirmed) {
              const response = await logout();
              if (response.status) {
                localStorage.removeItem("uid");
                localStorage.removeItem("lastReq");
                router.replace({ name: "Login" });
              }
            }
          });
      }
    };
    return { handleClickBack };
  },
};
</script>

<style lang="scss"></style>
