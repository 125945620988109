<template>
  <div>
    <div v-for="(playbackList, index) in playbackList" :key="index">
      <div
        v-if="playbackList.id === price.id"
        class="row justify-content-center align-items-center mx-0 my-2 py-2 history-card"
      >
        <div class="col-1 pe-0 text-center">
          <span
            class="color-round"
            :class="
              playbackList.result === 'wait'
                ? 'orange'
                : playbackList.result === 'home'
                ? 'red'
                : playbackList.result === 'away'
                ? 'blue'
                : ''
            "
            style="width: 1.25rem; height: 1.25rem"
          ></span>
        </div>
        <div class="col-10">
          <div class="h5">#{{ playbackList.id }}</div>
          <div>{{ playbackList.fullDate }}</div>
        </div>
        <div
          @click="handleClickBtnPlay(playbackList.id)"
          class="col-1 h4 mb-0 cursor-pointer"
        >
          <i class="fas fa-play"></i>
        </div>
      </div>
    </div>

    <button
      @click="handleClickBtnPlay(null)"
      class="btn btn-white w-25 float-end"
    >
      <div class="d-flex justify-content-center">
        <i class="history1-icon"></i>&nbsp;
        <span>ดูย้อนหลัง</span>
      </div>
    </button>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      price: computed(() => store.state.priceModule.price),
      playbackList: computed(() => store.state.priceModule.playbackList),
    });

    const handleClickBtnPlay = (id = null) => {
      if (id !== null) {
        store.dispatch("priceModule/setPlaybackId", id);
      }
      console.log("video", id);
      router.replace({ name: "ControlHistoryList" });
    };
    return {
      ...toRefs(state),
      handleClickBtnPlay,
    };
  },
};
</script>

<style lang="scss" scoped></style>
