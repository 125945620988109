import { createRouter, createWebHistory } from "vue-router";
import ControlLayout from "../layouts/ControlLayout.vue";
import Login from "../views/login/Login.vue";
import Match from "../views/match/Match.vue";
import controlRoutes from "./control/control-route.js";
import ControlHistoryList from "@/views/control/ControlHistoryList.vue";
import store from "@/store/index";
// import Cookies from "js-cookie";

const routes = [
  {
    path: "/",
    name: "Match",
    meta: { requireAuth: true },
    component: Match,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/control/:id/history/list",
    name: "ControlHistoryList",
    component: ControlHistoryList,
    meta: { requireAuth: true },
  },
  {
    path: "/control",
    name: "ControlLayout",
    component: ControlLayout,
    meta: { requireAuth: true },
    children: [...controlRoutes],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("priceModule/setTabNum", to.name);
  if (to.matched.some((record) => record.meta.requireAuth)) {
    const token = localStorage.getItem("uid");
    // const cookie = Cookies.get("XSRF-TOKEN");
    console.log(token);
    if (token === null) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
