<template>
  <div class="row py-3 head-div">
    <div class="col-10 ps-5">
      <div class="control-head-text">
        <div v-if="!isLoading" class="position-relative">
          <div class="color-box bg-red"></div>
          <div
            @click="handleClickName(price.mode, 'home', price.home_name)"
            class="text-truncate"
            :class="price.mode === 'auto_s' ? 'cursor-pointer' : ''"
            :data-bs-toggle="price.mode === 'auto_s' ? 'modal' : ''"
            data-bs-target="#TeamStatModal"
          >
            {{ price.home_name }}
          </div>
        </div>
        <div v-if="!isLoading" class="position-relative">
          <div class="color-box bg-blue"></div>
          <div
            @click="handleClickName(price.mode, 'away', price.away_name)"
            class="text-truncate"
            :class="price.mode === 'auto_s' ? 'cursor-pointer' : ''"
            :data-bs-toggle="price.mode === 'auto_s' ? 'modal' : ''"
            data-bs-target="#TeamStatModal"
          >
            {{ price.away_name }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-2">
      <!-- #FFEC88 -->
      <button
        v-if="priceInfo.rank_price !== null"
        class="btn btn-ranking active h-100"
        data-bs-toggle="modal"
        data-bs-target="#rankingModal"
      >
        {{ priceInfo.rank_price }}
      </button>
    </div>
  </div>
  <div class="row bg-dark round-div">
    <Flickity :price="price" />
  </div>
  <div class="row bg-dark">
    <div
      class="col d-flex justify-content-center align-items-center control-stream"
      :class="tabs === 'ControlIndex' ? 'active' : ''"
      @click="handleClickControlTab('index')"
    >
      <i class="control-icon pe-none"></i>
    </div>
    <div
      class="col d-flex justify-content-center align-items-center control-stream"
      :class="tabs === 'ControlPrice' ? 'active' : ''"
      @click="handleClickControlTab('price')"
    >
      <i class="price-icon pe-none"></i>
    </div>
    <div
      class="col control-stream"
      :class="tabs === 'ControlResult' ? 'active' : ''"
      @click="handleClickControlTab('result')"
    >
      <i class="fas fa-crown pe-none"></i>
    </div>
    <div
      v-if="price.playback === true"
      class="col d-flex justify-content-center align-items-center control-stream"
      :class="tabs === 'ControlHistory' ? 'active' : ''"
      @click="handleClickControlTab('history')"
    >
      <i class="history-icon pe-none"></i>
    </div>
  </div>
  <ModalRank />
  <ModalTeamStat :stat="stat" />
</template>

<script>
import { computed, onUpdated, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import Flickity from "@/components/Flickity.vue";
import { useStore } from "vuex";
import ModalRank from "@/components/modal/ModalRank.vue";
import ModalTeamStat from "@/components/modal/ModalTeamStat.vue";

export default {
  name: "ControlHead",
  props: ["price", "isLoading"],
  components: {
    Flickity,
    ModalRank,
    ModalTeamStat,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      priceInfo: computed(() => store.state.priceModule.priceInfo),
      tabs: computed(() => store.state.priceModule.tabNum),
      gameStat: computed(() => store.state.priceModule.gameStat),
      stat: { side: "", name: "", win: 0, draw: 0, lose: 0 },
    });
    onUpdated(() => {
      const container = document.querySelector(".container");
      const controlHead = document.querySelector(".control-head-text");
      const containerWidth = (container.clientWidth * 95) / 100;
      if (controlHead.clientWidth > containerWidth) {
        controlHead.style.width = "95%";
      }
    });
    const handleClickControlTab = async (page) => {
      if (page === "index") {
        await router.replace({ name: "ControlIndex" });
      } else if (page === "price") {
        await router.replace({ name: "ControlPrice" });
      } else if (page === "result") {
        await router.replace({ name: "ControlResult" });
      } else {
        await router.replace({ name: "ControlHistory" });
      }
    };
    const handleClickName = (mode, side, name) => {
      if (mode !== "auto_s") {
        return;
      }
      state.stat.win = 0;
      state.stat.draw = 0;
      state.stat.lose = 0;
      state.stat.side = side;
      state.stat.name = name;
      for (let index = 0; index < state.gameStat.length; index++) {
        const element = state.gameStat[index];
        if (element === side) {
          state.stat.win++;
        } else if (element === "draw") {
          state.stat.draw++;
        } else {
          state.stat.lose++;
        }
      }
    };
    return {
      ...toRefs(state),
      handleClickControlTab,
      handleClickName,
    };
  },
};
</script>

<style lang="scss" scoped></style>
