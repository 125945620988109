import Swal from "sweetalert2";

export function swalConfirm1() {
  const swalCf1 = Swal.mixin({
    customClass: {
      container: "swal-container",
      popup: "swal-popup",
      title: "swal-title",
      htmlContainer: "swal-html-container",
      actions: "swal-actions",
      confirmButton: "swal-confirm-btn",
      cancelButton: "swal-cancel-btn",
      denyButton: "swal-deny-btn",
    },
  });
  return { swalCf1 };
}

export function swalConfirm2() {
  const swalCf2 = Swal.mixin({
    customClass: {
      container: "swal-container",
      popup: "swal-popup",
      htmlContainer: "swal-html-container",
      actions: "swal-actions2",
      confirmButton: "swal-confirm-btn2",
      cancelButton: "swal-cancel-btn2",
    },
  });
  return { swalCf2 };
}

export function swalError() {
  const swalErr = Swal.mixin({
    customClass: {
      container: "swal-container",
      popup: "swal-popup",
      title: "swal-title",
      htmlContainer: "swal-html-container",
      actions: "swal-actions",
      confirmButton: "swal-confirm-btn",
      cancelButton: "swal-cancel-btn",
    },
  });
  return { swalErr };
}
