<template>
  <div
    class="modal fade modal-custom"
    :id="id"
    tabindex="-1"
    :aria-labelledby="id + 'Label'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" :class="bgcolor">
        <div v-if="close" class="btn-modal-close" data-bs-dismiss="modal">
          <i class="fas fa-times"></i>
        </div>
        <div class="modal-header border-bottom-0">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="content"></slot>
        </div>
        <div class="modal-footer position-relative border-top-0">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["bgcolor", "id", "close"],
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
