<template>
  <div class="container position-relative bg-grey">
    <Header :price="price" back="Match" />
    <VideoPlayer />
    <main id="main">
      <ControlHead :price="price" :isLoading="isLoading" />
      <router-view></router-view>
    </main>
    <ControlFooter />
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, watch } from "vue";

import Header from "@/components/Header.vue";
import ControlHead from "@/components/control/ControlHead.vue";
import ControlFooter from "@/components/control/ControlFooter.vue";
import { useLogin } from "@/views/login/composable/use-login";
import { useStore } from "vuex";
import { computed, reactive, toRefs } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { useIndex } from "@/views/control/composable/use-control";
import { swalConfirm1 } from "@/utilities/sweet-alert.js";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { utilily } from "@/utilities/utility";

export default {
  name: "ControlLayout",
  components: {
    Header,
    ControlHead,
    ControlFooter,
    VideoPlayer,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const { calculateHeight } = useLogin();
    const { updateMainPage } = useIndex();
    const { swalCf1 } = swalConfirm1();
    const { checkNewGame } = utilily();
    const state = reactive({
      price: computed(() => store.state.priceModule.price),
      priceInfo: computed(() => store.state.priceModule.priceInfo),
      isLoading: false,
      countTime: 0,
    });
    // const price = computed(() => store.state.priceModule.price);
    onBeforeUnmount(() => {
      store.dispatch("priceModule/setRoundPrice", {});

      clearInterval(window.infointv);
      clearInterval(window.newGameIntv);
      clearInterval(window.newGameCountIntv);
    });
    watch(
      () => [state.priceInfo.result],
      ([result], [prevResult]) => {
        if (
          result !== prevResult &&
          prevResult !== "home" &&
          prevResult !== "away" &&
          typeof prevResult !== "undefined"
        ) {
          clearInterval(window.newGameIntv);
          clearInterval(window.newGameCountIntv);
          window.newGameIntv = setInterval(async () => {
            const isNewGame = await checkNewGame(state.price);
            console.log("isNewGame", isNewGame);
            if (isNewGame !== false) {
              clearInterval(window.newGameIntv);
              state.countTime = 10;
              window.newGameCountIntv = setInterval(async () => {
                state.countTime = state.countTime - 1;
                const btn = document.querySelector(".swal-confirm-btn");
                if (btn) {
                  btn.innerHTML = "ไปคู่ต่อไป ( " + state.countTime + " )";
                }
                if (state.countTime <= 0) {
                  clearInterval(window.newGameCountIntv);
                  await router.push({
                    name: "ControlIndex",
                    params: { id: isNewGame.id },
                  });
                  swalCf1.close();
                  router.go();
                }
              }, 1000);
              swalCf1
                .fire({
                  buttonsStyling: false,
                  showCancelButton: true,
                  reverseButtons: true,
                  html: `<div class="w-100">
                    <div style='color:#000'>คู่ต่อไป #${
                      isNewGame.additional
                        ? isNewGame.additional.match_video_id
                          ? isNewGame.id +
                            "/" +
                            isNewGame.additional.match_video_id
                          : isNewGame.id
                        : isNewGame.id
                    }</div>
                    <div class="new-game-list mt-3 cursor-pointer text-start">
                      <div class="list-name position-relative">
                        <div class="position-relative">
                          <div class="color-box bg-red"></div>
                          <div class="text-truncate ps-2">${
                            isNewGame.home_name
                          }</div>
                        </div>
                        <div class="position-relative">
                          <div class="color-box bg-blue"></div>
                          <div class="text-truncate ps-2">${
                            isNewGame.away_name
                          }</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  `,
                  cancelButtonText: "ยกเลิก",
                  cancelButtonColor: "#d9d9d9",
                  confirmButtonText: "ไปคู่ต่อไป ( " + state.countTime + " )",
                  confirmButtonColor: "#ffffff",
                  background: "#e5e5e5",
                })
                .then(async (isConfirm) => {
                  if (isConfirm.isConfirmed) {
                    clearInterval(window.newGameCountIntv);
                    await router.push({
                      name: "ControlIndex",
                      params: { id: isNewGame.id },
                    });
                    router.go();
                  } else {
                    clearInterval(window.newGameCountIntv);
                  }
                });
            }
          }, 3000);
        }
      }
    );
    onMounted(async () => {
      const id = route.params.id;
      await calculateHeight();
      state.isLoading = true;
      const container = document.querySelector(".container");
      const header = document.querySelector("#header");
      const footer = document.querySelector(".footer");
      const main = document.querySelector("#main");
      if (
        container.clientHeight <
        header.clientHeight + footer.clientHeight + main.clientHeight
      ) {
        const height =
          container.clientHeight - (header.clientHeight + footer.clientHeight);

        main.style.height = height + "px";
        main.classList.add("row", "scroll-content");
        console.log("height", height, header.clientHeight, footer.clientHeight);
      }

      if (id !== undefined) {
        clearInterval(window.infointv);
        await store.dispatch("priceModule/getPrice", id);
        if (state.price.mode === "auto_s") {
          await store.dispatch("priceModule/getGameStat", id);
        }

        state.isLoading = false;
        window.infointv = setInterval(async () => {
          if (route.name === "Match") {
            clearInterval(window.infointv);
            return;
          }
          updateMainPage(route, store);
        }, 500);
        await store.dispatch(
          "priceModule/getPlaybackList",
          state.price.league_id
        );
      } else {
        swalCf1
          .fire({
            buttonsStyling: true,
            showCancelButton: true,
            showConfirmButton: false,
            html: "ระบบขัดข้อง กรุณาเลือกคู่ใหม่อีกครั้ง",
            cancelButtonText: "ปิด",
            cancelButtonColor: "#fff",
            background: "#ff7070",
            color: "#fff",
          })
          .then(async (isConfirm) => {
            if (!isConfirm.isConfirmed) {
              await router.replace({
                name: "Match",
              });
            }
          });
      }
    });
    return { ...toRefs(state) };
  },
};
</script>

<style lang="scss" scoped></style>
