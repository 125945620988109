import { getMatch } from "@/services/match-service";
import { getTeamList } from "@/services/price-service";
import store from "@/store/index";
export function utilily() {
  const handleClickCreateNewGame = async (id) => {
    const key = "matchLists_L" + id;
    await store.dispatch("priceModule/getNameLists", key);
    const response = await getTeamList(id);
    console.log("Team list", response);
    if (response.status === true) {
      store.dispatch("priceModule/setGameSelect", response.data);
    }
  };
  const checkNewGame = async (price) => {
    const response = await getMatch();
    let returnData = false;
    if (response.status === true) {
      console.log(response.data);
      const foundObject = response.data.find(
        (obj) => obj.league_id === price.league_id
      );
      console.log("foundObject", foundObject);
      if (foundObject) {
        for (let index = 0; index < foundObject.game_list.length; index++) {
          const element = foundObject.game_list[index];
          if (element.status === "live" && element.id !== price.id) {
            returnData = element;
            break;
          }
        }
      }
    }
    return returnData;
  };
  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj[key] !== undefined) {
        return false;
      }
    }
    return true;
  };
  const numberWithCommas = (number = null, decimal, pool = false) => {
    if (!number) {
      return 0;
    }
    var fixedNumber;
    if (pool === true) {
      fixedNumber = parseFloat(number).toFixed(decimal);
      return String(fixedNumber);
    } else {
      fixedNumber = parseFloat(
        parseFloat(number).toFixed(decimal)
      ).toLocaleString("en-IN", {
        useGrouping: true,
      });
      return String(fixedNumber.replace(/,/g, "")).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
    }
  };
  const findTax = (percentageReward, commission_table) => {
    var tax = 0;
    if (commission_table !== null) {
      for (let index = commission_table.length; index > 0; index--) {
        const element = commission_table[index - 1];
        if (percentageReward >= element.persent_reward) {
          tax = element.persent_tax;
          return tax;
        }
      }
      tax = commission_table[0].persent_tax;
    }
    return tax;
  };
  const calculatePercentageReward = (a, b, commission_table) => {
    var percentageReward = 0;
    var commissionReward = 0;
    var customerReward = 0;
    var percentageTax = 0;

    percentageReward = (b / a) * 100;
    percentageTax = findTax(percentageReward, commission_table);
    commissionReward = (b / 100) * percentageTax;
    customerReward = (b - commissionReward) / a + 1;
    // customerReward = ((b - commissionReward) / a) * 100 + 100;

    return customerReward;
  };
  return {
    numberWithCommas,
    calculatePercentageReward,
    isEmpty,
    checkNewGame,
    handleClickCreateNewGame,
  };
}
