<template>
  <div class="container position-relative bg-grey">
    <Header :price="price" back="ControlHistory" />
    <div
      id="search-tab"
      class="d-flex justify-content-between align-items-center history-list my-2"
    >
      <div>
        <span v-if="playbackId !== null">คู่ที่ {{ playbackId }}</span>
      </div>

      <div class="input-group w-50">
        <input type="text" class="form-control" placeholder="ค้นหา ID" />
        <span class="input-group-text">
          <i class="fas fa-search"></i>
        </span>
      </div>
    </div>
    <div id="video-tab">
      <video
        id="history-video"
        class="video-js vjs-16-9"
        style="min-height: 250px"
        playsinline
      ></video>
      <!-- <video id="history-video" class="w-100" controls>
        <source
          :src="isEmpty(playbackURL) === true ? playbackURL : ''"
          type="application/x-mpegURL"
        />
      </video> -->
    </div>
    <div id="btn-tab" class="row mt-1">
      <div class="col">
        <button
          @click="handleClickBackWard(10)"
          class="btn btn-white vstack p-0"
        >
          <div class="w-100">
            <i class="fas fa-fast-backward"></i>
          </div>
          <span class="w-100">10</span>
        </button>
      </div>
      <div class="col">
        <button
          @click="handleClickBackWard(5)"
          class="btn btn-white vstack p-0"
        >
          <div class="w-100">
            <i class="fas fa-step-backward"></i>
          </div>
          <span class="w-100">5</span>
        </button>
      </div>
      <div class="col">
        <button @click="handleClickForWard(5)" class="btn btn-white vstack p-0">
          <div class="w-100">
            <i class="fas fa-step-forward"></i>
          </div>
          <span class="w-100">5</span>
        </button>
      </div>
      <div class="col">
        <button
          @click="handleClickForWard(10)"
          class="btn btn-white vstack p-0"
        >
          <div class="w-100">
            <i class="fas fa-fast-forward"></i>
          </div>
          <span class="w-100">10</span>
        </button>
      </div>
    </div>
    <div id="list-tab" class="row mt-2 mx-0">
      <div
        v-for="(playbackList, index) in playbackList"
        :key="index"
        @click="handleClickMatchHistory(playbackList.id)"
        class="row justify-content-center align-items-center mx-0 my-1 py-2 history-card cursor-pointer"
      >
        <div class="col-1 pe-0 text-center">
          <span
            class="color-round"
            :class="
              playbackList.result === 'wait'
                ? 'orange'
                : playbackList.result === 'home'
                ? 'red'
                : playbackList.result === 'away'
                ? 'blue'
                : ''
            "
            style="width: 1.25rem; height: 1.25rem"
          ></span>
        </div>
        <div class="col-10">
          <div class="h5">
            #{{ playbackList.id
            }}{{
              playbackList.additional !== null
                ? playbackList.additional.match_video_id
                  ? "/" + playbackList.additional.match_video_id
                  : ""
                : ""
            }}
          </div>
          <div>{{ playbackList.fullDate }}</div>
        </div>
        <div class="col-1 h4 mb-0 cursor-pointer">
          <div
            v-if="playbackId !== playbackList.id"
            @click.stop="handleClickBtnPlayback(playbackList.id, true)"
          >
            <i class="fas fa-play"></i>
          </div>
          <div v-else>
            <div
              v-if="isPlay === true"
              @click.stop="handleClickBtnPlayback(playbackList.id, false)"
            >
              <i class="fas fa-pause"></i>
            </div>
            <div
              v-else
              @click.stop="handleClickBtnPlayback(playbackList.id, true, true)"
            >
              <i class="fas fa-play"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, reactive, toRefs } from "vue";
import Header from "@/components/Header.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { utilily } from "@/utilities/utility";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default {
  components: {
    Header,
  },
  setup() {
    const { isEmpty } = utilily();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      price: computed(() => store.state.priceModule.price),
      playbackList: computed(() => store.state.priceModule.playbackList),
      playbackId: computed(() => store.state.priceModule.playbackId),
      playbackURL: computed(() => store.state.priceModule.playbackURL),
      videoPlayer: null,
      isPlay: false,
    });
    onBeforeUnmount(async () => {
      await store.dispatch("priceModule/setPlaybackId", null);
    });
    onMounted(async () => {
      if (state.playbackList.length <= 0) {
        await store.dispatch("priceModule/getPrice", route.params.id);
        await store.dispatch(
          "priceModule/getPlaybackList",
          state.price.league_id
        );
      }
      if (state.playbackId !== null) {
        state.isPlay = true;
        await store.dispatch("priceModule/getPlaybackURL", state.playbackId);
      }
      setVideoJS();
      const container = document.querySelector(".container");
      const header = document.querySelector("#header");
      const search = document.querySelector("#search-tab");
      const video = document.querySelector("#video-tab");
      const btn = document.querySelector("#btn-tab");
      const list = document.querySelector("#list-tab");
      if (
        container.offsetHeight <
        header.offsetHeight +
          search.offsetHeight +
          video.offsetHeight +
          btn.offsetHeight +
          list.offsetHeight
      ) {
        const height =
          container.offsetHeight -
          (header.offsetHeight +
            search.offsetHeight +
            video.offsetHeight +
            btn.offsetHeight);

        list.style.height = height - 40 + "px";
        list.classList.add("row", "scroll-content");
        // console.log(
        //   "height",
        //   height,
        //   container.offsetHeight,
        //   header.offsetHeight,
        //   search.offsetHeight,
        //   video.offsetHeight,
        //   btn.offsetHeight
        // );
      }
    });
    const handleClickBackWard = (time) => {
      const vid = document.querySelector("#history-video");
      vid.currentTime = vid.currentTime - time;
    };
    const handleClickForWard = (time) => {
      const vid = document.querySelector("#history-video");
      vid.currentTime = vid.currentTime + time;
    };
    const handleClickBtnPlayback = async (id, isPlay, isContinue = false) => {
      state.isPlay = isPlay;
      if (isPlay === true && isContinue === false) {
        state.videoPlayer.dispose();
        await store.dispatch("priceModule/setPlaybackId", id);
        await store.dispatch("priceModule/getPlaybackURL", state.playbackId);
        setVideoJS();
      } else if (isContinue === true) {
        state.videoPlayer.play();
      } else {
        state.videoPlayer.pause();
      }
      console.log(isPlay, state.playbackId, id);
    };
    const handleClickMatchHistory = (id) => {
      router.push({ name: "ControlIndex", params: { id: id } });
    };
    const setVideoJS = () => {
      let videoOptions;
      state.playbackId !== null
        ? (videoOptions = {
            autoplay: true,
            controls: true,
            muted: true,
            restoreEl: true,
            sources: [
              {
                src: state.playbackURL.url,
                type: "application/x-mpegURL",
              },
            ],
          })
        : (videoOptions = {
            autoplay: true,
            controls: true,
            muted: true,
            restoreEl: true,
          });

      const videoInv = setInterval(() => {
        const videoTag = document.querySelector("video");
        if (videoTag) {
          clearInterval(videoInv);
          // videoTag.style.width = "100%";
          // videoTag.style.height = "100%";
          state.videoPlayer = videojs(
            videoTag,
            videoOptions,
            function onPlayerReady() {
              console.log("onPlayerReady");
            }
          );
          // state.videoPlayer.on("error", function () {
          //   console.log("vdo js error occured ", state.videoPlayer.error());
          // });
        }
      }, 100);
    };
    return {
      ...toRefs(state),
      handleClickBackWard,
      handleClickForWard,
      handleClickBtnPlayback,
      handleClickMatchHistory,
      isEmpty,
    };
  },
};
</script>

<style lang="scss" scoped></style>
