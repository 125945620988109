import { request } from "./api";

export function login(data) {
  const url = `/streamer/streamer-login`;
  return request("post", url, data, false);
}

export function logout() {
  const url = `/streamer/logout`;
  return request("get", url, {});
}
