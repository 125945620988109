<template>
  <div class="badge badge-live">
    <div class="m-auto">
      <div :id="id" class="live-animated" />
      <span>LIVE</span>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import lottie from "lottie-web";
import json from "@/assets/images/live.json";

export default {
  name: "LiveIcon",
  props: ["id"],
  setup(props) {
    onMounted(() => {
      lottie.loadAnimation({
        container: document.querySelector(`#${props.id}`),
        renderer: "svg",
        animationData: json,
      });
    });
    // lavContainer
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
