import axios from "axios";
import router from "@/router/index";
import { swalConfirm1 } from "@/utilities/sweet-alert.js";

const BASE_API_URL = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : "https://api-dev.muaysod.com";

const http = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
// axios.defaults.withCredentials = true;
// axios.defaults.headers.common = {
//   "X-Requested-With": "XMLHttpRequest",
//   Accept: "application/json",
// };
var swalOption = {
  buttonsStyling: true,
  showCancelButton: true,
  showConfirmButton: false,
  html: "",
  cancelButtonText: "ปิด",
  cancelButtonColor: "#fff",
  background: "#ff7070",
};

export async function request(method, url, data, csrf = false) {
  const { swalCf1 } = swalConfirm1();
  var response;
  try {
    if (csrf) {
      await http.get(`/sanctum/csrf-cookie`).then(async () => {
        response = await http({
          method,
          url,
          data,
        });
      });
    } else {
      response = await http({
        method,
        url,
        data,
      });
    }
  } catch (error) {
    console.log("error ->>", error.response);
    if (error.response.status === 401) {
      swalOption.html = `<span style='color:#fff'>${error.response.data.message}<span>`;
      swalCf1.fire(swalOption).then((isConfirm) => {
        if (!isConfirm.isConfirmed) {
          router.replace({ name: "Login" });
        }
      });
    }
  }
  if (!response.data.status) {
    if (response.data.message === "The selected id is invalid.") {
      router.replace({ name: "Match" });
    }
    swalOption.html = `<span style='color:#fff'>${response.data.message}<span>`;
    swalCf1.fire(swalOption);
  }
  return response.data;
}
