<template>
  <div class="footer">
    <div class="row mx-0 my-2-5">
      <div class="col-9">
        <button @click="handleClicBtnStart" class="btn btn-white btn-streamimg">
          <span v-if="!isStart">เริ่มถ่ายทอดเสียง</span>
          <span v-else>หยุดถ่ายทอดเสียง</span>
        </button>
      </div>
      <div class="col-3 px-0 custom-flex">
        <div class="backgroud-icon">
          <div
            v-if="micMute"
            @click="handleClickMicrophone"
            class="footer-icon"
          >
            <i class="fas fa-microphone-slash"></i>
          </div>
          <div v-else @click="handleClickMicrophone" class="footer-icon">
            <i class="fas fa-microphone"></i>
          </div>
        </div>
        <div class="backgroud-icon">
          <div
            @click="handleClickSummary"
            class="footer-icon"
            data-bs-toggle="modal"
            data-bs-target="#resultModal"
          >
            <i class="fas fa-clipboard-list"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal bgcolor="grey" id="resultModal">
    <template #header>
      <span class="result-header">ยอดรวมปัจจุบัน</span>
      <button
        type="button"
        class="btn-close result-header m-0"
        data-bs-dismiss="modal"
      ></button>
      <button
        @click="handleClickSummary"
        type="button"
        class="btn-reset result-header m-0"
      >
        <i class="fas fa-redo-alt"></i>
      </button>
    </template>

    <template #content>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 mb-2 px-1">
            <div class="bg-pink result-modal-content">
              <div>ยอดรวมแดง</div>
              <div class="lh-lg">
                {{ numberWithCommas(priceSum.total_home_amount, 0) }}
              </div>
            </div>
          </div>
          <div class="col-6 mb-2 px-1">
            <div class="bg-sky result-modal-content">
              <div>ยอดรวมน้ำเงิน</div>
              <div class="lh-lg">
                {{ numberWithCommas(priceSum.total_away_amount, 0) }}
              </div>
            </div>
          </div>
          <div
            v-if="priceInfo.result === 'home' || priceInfo.result === 'away'"
            class="col-12 mb-2 px-1"
          >
            <div
              class="result-modal-content text-white"
              :class="
                priceInfo.result === 'home' ? 'bg-softred' : 'bg-softblue'
              "
            >
              <div v-if="priceInfo.result === 'home'">แดงชนะ</div>
              <div v-else>น้ำเงินชนะ</div>
              <div v-if="priceInfo.result === 'home'" class="lh-lg">
                {{
                  numberWithCommas(
                    priceSum.total_home_amount +
                      priceSum.total_away_amount -
                      priceSum.if_home_win,
                    0
                  )
                }}
              </div>
              <div v-else class="lh-lg">
                {{
                  numberWithCommas(
                    priceSum.total_home_amount +
                      priceSum.total_away_amount -
                      priceSum.if_away_win,
                    0
                  )
                }}
              </div>
            </div>
          </div>
          <div
            v-if="priceInfo.result !== 'home' && priceInfo.result !== 'away'"
            class="col-6 mb-2 px-1"
          >
            <div class="bg-softred result-modal-content text-white">
              <div>แดงชนะ</div>
              <div class="lh-lg">
                {{
                  numberWithCommas(
                    priceSum.total_home_amount +
                      priceSum.total_away_amount -
                      priceSum.if_home_win,
                    0
                  )
                }}
              </div>
            </div>
          </div>
          <div
            v-if="priceInfo.result !== 'home' && priceInfo.result !== 'away'"
            class="col-6 mb-2 px-1"
          >
            <div class="bg-softblue result-modal-content text-white">
              <div>น้ำเงินชนะ</div>
              <div class="lh-lg">
                {{
                  numberWithCommas(
                    priceSum.total_home_amount +
                      priceSum.total_away_amount -
                      priceSum.if_away_win,
                    0
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer></template>
  </Modal>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, reactive, toRefs } from "vue";
import { useIndex } from "@/views/control/composable/use-control";
import { swalConfirm1 } from "@/utilities/sweet-alert.js";
import Modal from "@/components/Modal.vue";
import { getGamePriceSum, getLiveToken } from "@/services/price-service";
import { utilily } from "@/utilities/utility";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { createLocalTracks, RoomEvent, Room } from "livekit-client";

export default {
  name: "ControlFooter",
  components: {
    Modal,
  },
  setup() {
    const { addElementClass, removeElementClass } = useIndex();
    const { swalCf1 } = swalConfirm1();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      priceInfo: computed(() => store.state.priceModule.priceInfo),
      league_id: computed(() => store.state.priceModule.price.league_id),
      micMute: false,
      isStart: false,
      priceSum: {
        total_ticket: 0,
        total_home_amount: 0,
        total_home_ticket: 0,
        total_away_amount: 0,
        total_away_ticket: 0,
        if_home_win: 0,
        if_away_win: 0,
      },
      room: undefined,
    });
    onBeforeUnmount(() => {
      stopStream();
    });
    onMounted(() => {
      initRoom();
    });
    const initRoom = async () => {
      state.room = new Room();
      state.room.on(
        RoomEvent.Disconnected,
        (track, publication, participant) => {
          console.log("Disconnected :: ", track, publication, participant);
          state.isStart = false;
          //console.log("Participant Subscribed");
          //attachTrack(track, participant);
        }
      );
    };
    const startStream = async () => {
      const listenerToken = await getLiveToken(
        state.league_id,
        route.params.id
      );
      console.log("token", listenerToken);
      const token = listenerToken.data.token;

      const url = process.env.VUE_APP_LIVEKIT_URL
        ? process.env.VUE_APP_LIVEKIT_URL
        : "wss://livekit-test.muaysod.com";

      // const room = await connect(url, t); //

      console.log("Room State Before : ", state.room.state);
      console.log("Room : ", state.room);

      let connectResult = await state.room.connect(url, token);
      if (connectResult === undefined) {
        return false;
      }
      // console.log("create tracks");
      // connectResult.stated

      const tracks = await createLocalTracks({
        audio: true,
        video: false,
      });
      for (let track of tracks) {
        console.log("publicsh track : ", track);
        await state.room.localParticipant.publishTrack(track);

        console.log("Publishing Track");
        console.log(track);
        if (track.kind === "video") {
          const v = document.getElementById("us");
          track.attach(v);
        }
      }
    };
    const stopStream = () => {
      state.room.disconnect(true);
    };
    const mute = (mode) => {
      console.log(mode);
      let microphoneTrack = state.room.localParticipant.getTrack("microphone");
      if (microphoneTrack === undefined) {
        return false;
      }
      if (mode === true) {
        microphoneTrack.mute();
      } else {
        microphoneTrack.unmute();
      }
    };
    const handleClickSummary = async () => {
      const data = JSON.parse(`{"id":${route.params.id}}`);
      const response = await getGamePriceSum(data);
      if (response.status) {
        state.priceSum = response.data;
        console.log("sum", state.priceSum);
        state.priceSum.total_home_amount =
          state.priceSum.total_home_amount / 100;
        state.priceSum.total_away_amount =
          state.priceSum.total_away_amount / 100;
        state.priceSum.if_home_win = state.priceSum.if_home_win / 100;
        state.priceSum.if_away_win = state.priceSum.if_away_win / 100;
      }
    };
    const handleClickMicrophone = () => {
      if (state.micMute) {
        mute(false);
      } else {
        mute(true);
      }
      state.micMute = !state.micMute;
    };
    const handleClicBtnStart = () => {
      var html;
      if (state.isStart) {
        html = "<span style='color:#000'>ยืนยันการหยุดสตรีม</span>";
      } else {
        html = "<span style='color:#000'>ยืนยันการเริ่มสตรีม</span>";
      }
      swalCf1
        .fire({
          buttonsStyling: false,
          showCancelButton: true,
          reverseButtons: true,
          html: html,
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "<i class='fas fa-check-circle'></i> ยืนยัน",
          confirmButtonColor: "#ffffff",
          background: "#e5e5e5",
        })
        .then((isConfirm) => {
          if (isConfirm.isConfirmed) {
            if (!state.isStart) {
              startStream();
              state.isStart = true;
            } else {
              stopStream();
              state.isStart = false;
            }
            const btn = document.querySelector(".btn-streamimg");
            if (btn.classList.contains("active")) {
              removeElementClass(btn, "active");
            } else {
              addElementClass(btn, "active");
            }
          }
        });
    };
    const { numberWithCommas } = utilily();
    return {
      ...toRefs(state),
      startStream,
      stopStream,
      mute,
      handleClickSummary,
      handleClickMicrophone,
      handleClicBtnStart,
      numberWithCommas,
    };
  },
};
</script>

<style lang="scss" scoped></style>
