<template>
  <RoundPrice
    :timeLeft="timeLeft"
    :isTable="isTable"
    @handleClickChangePrice="handleClickChangePrice"
  />

  <div class="row py-2 header-price-text">
    <div
      v-if="priceInfo.mode === 'pool'"
      class="text-lefttab white w-auto pe-0 cursor-pointer"
    >
      <div v-if="isVideo === false" @click="handleClickVideo(true)">
        <i class="fas fa-video"></i>
      </div>
      <div v-else @click="handleClickVideo(false)">
        <i class="fas fa-video-slash"></i>
      </div>
    </div>
    <span id="text-control">ควบคุมรอบบิล</span>
  </div>
  <div class="row g-1 g-lg-2 py-3">
    <div class="col">
      <button
        @click="handleClickBtnClosePrice(timeLeft <= 0 ? false : true)"
        class="btn btn-softred"
        :data-bs-toggle="timeLeft <= 0 ? '' : 'modal'"
        data-bs-target="#delayPrice"
      >
        <i class="fas fa-times-circle"></i> ปิดราคา
      </button>
    </div>
    <div class="col">
      <button
        v-if="priceInfo.mode !== 'pool'"
        @click="handleClickBtnGetBill"
        class="btn btn-softgreen"
      >
        <i class="fas fa-check-circle"></i> รับบิล
      </button>
      <button
        v-else
        @click="handleClickBtnBar"
        class="btn btn-softgreen"
        data-bs-toggle="modal"
        :data-bs-target="timeLeft <= 0 ? '#drawModal' : '#delayPrice'"
      >
        <i class="fas fa-check-circle"></i> เปิดราคา
      </button>
    </div>
    <div class="col">
      <button
        v-if="priceInfo.is_pause === true"
        @click="handleClickSetPause(false)"
        class="btn btn-green"
      >
        เปิดรับ <i class="fas fa-play"></i>
      </button>
      <button v-else @click="handleClickSetPause(true)" class="btn btn-red">
        หยุดรับ <i class="fas fa-pause"></i>
      </button>
    </div>
  </div>
  <div v-if="priceInfo.mode !== 'pool'" class="row py-2 header-price-text">
    <div class="text-lefttab white w-auto pe-0 cursor-pointer">
      <div v-if="isVideo === false" @click="handleClickVideo(true)">
        <i class="fas fa-video"></i>
      </div>
      <div v-else @click="handleClickVideo(false)">
        <i class="fas fa-video-slash"></i>
      </div>
    </div>
    <span>ใส่ราคา</span>
    <div
      v-if="isMobile === false && isBargainDB === true"
      @click="handleClickTable()"
      class="text-rightab white w-auto pe-0 cursor-pointer"
    >
      <i class="fas fa-border-all"></i>
    </div>
  </div>
  <div v-if="priceInfo.mode !== 'pool'" class="row py-3 position-relative">
    <div class="col-4 pe-1">
      <button
        @click="handleClickBtnUpper()"
        class="btn btn-round-price"
        :class="[
          isSelectPriceType === 'upper' ? 'active' : '',
          priceInfo.is_pause === true ? 'cross' : '',
        ]"
        :data-bs-toggle="timeLeft <= 0 ? '' : 'modal'"
        data-bs-target="#delayPrice"
        :disabled="priceInfo.is_pause === true ? true : false"
      >
        ต่อ
      </button>
    </div>
    <div class="col-4 px-1">
      <button
        @click="handleClickBtnDraw()"
        class="btn btn-round-price"
        :class="[
          isSelectPriceType === 'draw' ? 'active' : '',
          priceInfo.is_pause === true ? 'cross' : '',
        ]"
        :data-bs-toggle="timeLeft <= 0 ? '' : 'modal'"
        data-bs-target="#delayPrice"
        :disabled="priceInfo.is_pause === true ? true : false"
      >
        เสมอ
      </button>
    </div>
    <div class="col-4 ps-1">
      <button
        @click="handleClickBtnBar()"
        class="btn btn-round-price"
        :class="[
          isSelectPriceType === 'bar' ? 'active' : '',
          priceInfo.is_pause === true ? 'cross' : '',
        ]"
        data-bs-toggle="modal"
        :data-bs-target="timeLeft <= 0 ? '#drawModal' : '#delayPrice'"
        :disabled="priceInfo.is_pause === true ? true : false"
      >
        ต่อรอง
      </button>
    </div>
  </div>
  <div class="row position-relative">
    <div
      v-if="
        approveTime >= 0 &&
        priceInfo.is_price_open === true &&
        priceInfo.auto_approve_time > 0 &&
        priceInfo.auto_approve_time !== null
      "
      class="close-time py-2"
    >
      <div><i class="fas fa-stopwatch"></i> {{ approveTime }}</div>
    </div>
    <div class="col-6">
      <div
        @click="handleClickBtnRedPrice"
        class="price-result red"
        :class="isSetPrice || priceInfo.home_price ? 'active' : ''"
        id="result-red"
        :data-bs-toggle="
          isSelectPriceType === 'upper' || isSelectPriceType === 'draw'
            ? 'modal'
            : ''
        "
        :data-bs-target="
          isSelectPriceType === 'upper' ? '#calculateModal' : '#drawModal'
        "
      >
        <div class="py-2 result-text-top">
          <span>{{
            priceInfo.home_price && priceInfo.is_price_open
              ? priceInfo.mode !== "pool"
                ? checkPrice(priceInfo.home_price)
                : numberWithCommas(
                    calculatePercentageReward(
                      priceInfo.total_home_amount,
                      priceInfo.total_away_amount,
                      priceInfo.commission ? priceInfo.commission : null
                    ),
                    2,
                    true
                  )
              : "-"
          }}</span>
        </div>
        <div class="py-2 result-text-center">
          <span
            >{{
              priceInfo.total_home_amount
                ? numberWithCommas(priceInfo.total_home_amount, 2)
                : "0"
            }}
            ฿</span
          >
        </div>
        <div class="py-2 result-text-bottom red">
          <span
            >{{
              priceInfo.total_home_ticket
                ? numberWithCommas(priceInfo.total_home_ticket, 2)
                : "0"
            }}
            บิล</span
          >
        </div>
      </div>
    </div>
    <div class="col-6">
      <div
        @click="handleClickBtnBluePrice"
        class="price-result blue"
        :class="isSetPrice || priceInfo.away_price ? 'active' : ''"
        id="result-blue"
        :data-bs-toggle="
          isSelectPriceType === 'upper' || isSelectPriceType === 'draw'
            ? 'modal'
            : ''
        "
        :data-bs-target="
          isSelectPriceType === 'upper' ? '#calculateModal' : '#drawModal'
        "
      >
        <div class="py-2 result-text-top">
          <span>{{
            priceInfo.away_price && priceInfo.is_price_open
              ? priceInfo.mode !== "pool"
                ? checkPrice(priceInfo.away_price)
                : numberWithCommas(
                    calculatePercentageReward(
                      priceInfo.total_away_amount,
                      priceInfo.total_home_amount,
                      priceInfo.commission ? priceInfo.commission : null
                    ),
                    2,
                    true
                  )
              : "-"
          }}</span>
        </div>
        <div class="py-2 result-text-center">
          <span
            >{{
              priceInfo.total_away_amount
                ? numberWithCommas(priceInfo.total_away_amount, 2)
                : "0"
            }}
            ฿</span
          >
        </div>
        <div class="py-2 result-text-bottom blue">
          <span
            >{{
              priceInfo.total_away_ticket
                ? numberWithCommas(priceInfo.total_away_ticket, 2)
                : "0"
            }}
            บิล</span
          >
        </div>
      </div>
    </div>
  </div>
  <!-- modal calculate -->
  <Modal bgcolor="pink" id="calculateModal">
    <template #header>
      <span v-if="selectPriceColor === 'red'" class="modal-title">แดงต่อ</span>
      <span v-else class="modal-title">น้ำเงินต่อ</span>
      <div
        v-if="isBargainDB === true"
        class="position-absolute end-0 translate-middle-x"
        style="color: #777777"
        @click="handleClickCalculator"
      >
        <div v-if="isToggleCalculator === true">
          <i class="fas fa-border-all"></i>
        </div>
        <div v-else><i class="fas fa-calculator"></i></div>
      </div>
    </template>

    <template #content>
      <div class="container-fluid">
        <div class="row">
          <div
            v-if="isBargainDB === true && isToggleCalculator === false"
            class="col-12 pb-3 px-1 d-flex justify-content-center"
          >
            <div
              @click="handleClickBtnShowBargain('red')"
              class="btn pricebox withstate custom-tooltip"
              :class="[
                bargainRedString || bargainBlueString !== '-' ? 'active' : '',
                selectPriceColor === 'red' ? 'btn-red' : 'btn-blue',
              ]"
            >
              {{
                selectPriceColor === "red"
                  ? bargainRedString
                  : bargainBlueString
              }}
            </div>
            <div class="btn statebox">
              <span
                v-if="
                  (selectPriceColor === 'red' && isBargainRedPass === false) ||
                  bargainRedString === '-' ||
                  (selectPriceColor === 'blue' &&
                    isBargainBluePass === false) ||
                  bargainBlueString === '-'
                "
                style="color: red"
              >
                <i class="fas fa-times"></i>
              </span>
              <span v-else style="color: green">
                <i class="fas fa-check"></i>
              </span>
            </div>
          </div>
          <div v-else class="col-12 pb-3 px-1">
            <div
              @click="handleClickBtnShowBargain('red')"
              class="btn btn-red pricebox custom-tooltip"
              :class="[
                isBargainRed ? 'selected' : '',
                bargainRedString !== '-' ? 'active' : '',
              ]"
            >
              <div
                v-if="!isBargainRedPass && bargainRedString !== '-'"
                class="tooltoptext"
              >
                กรุณากรอกให้ถูกต้อง
              </div>
              {{ bargainRedString }}
            </div>
            <div
              @click="handleClickBtnShowBargain('blue')"
              class="btn btn-blue pricebox custom-tooltip"
              :class="[
                isBargainBlue ? 'selected' : '',
                bargainBlueString !== '-' ? 'active' : '',
              ]"
            >
              <div
                v-if="!isBargainBluePass && bargainBlueString !== '-'"
                class="tooltoptext"
              >
                กรุณากรอกให้ถูกต้อง
              </div>
              {{ bargainBlueString }}
            </div>
          </div>
          <!-- <div v-for="number in bargainDB" :key="number" class="col-4 p-1">
            <button
              v-if="isBargainDB === true"
              :id="'digipadBtn' + number"
              @click="handleClickBargainNumpad(number)"
              class="btn "
            >
              {{ number }}
            </button>
          </div> -->

          <div v-for="number in bargainArr" :key="number" class="col-4 p-1">
            <button
              v-show="
                (number === 'A' &&
                  isBargainDB === true &&
                  isToggleCalculator === false) ||
                (number === 'B' &&
                  isBargainDB === true &&
                  isToggleCalculator === false) ||
                (number === 'C' &&
                  isBargainDB === true &&
                  isToggleCalculator === false) ||
                number === '1' ||
                number === '2' ||
                number === '3' ||
                number === '4' ||
                number === '5' ||
                number === '6' ||
                number === '7' ||
                number === '8' ||
                number === '9' ||
                number === '0'
              "
              :id="'digipadBtn' + number"
              @click="handleClickBargainNumpad(number)"
              class="btn"
              :class="[
                number === 'A' || number === 'B' || number === 'C'
                  ? 'btn-softgray'
                  : 'btn-white',
              ]"
            >
              {{ number === ".5" ? "0.5" : number }}
            </button>
          </div>
          <div v-show="isToggleCalculator === false" class="col-4 p-1">
            <button
              id="digipadBtn00"
              @click="handleClickBargainNumpad('00')"
              class="btn btn-white"
            >
              00
            </button>
          </div>
          <div class="col-4 p-1">
            <button
              id="digipadBtnHalf"
              @click="handleClickBargainNumpad('.5')"
              class="btn btn-white"
            >
              0.5
            </button>
          </div>
          <div v-show="isToggleCalculator === false" class="col-4 p-1">
            <button
              id="digipadSlash"
              @click="handleClickBargainNumpad('/')"
              class="btn btn-white"
            >
              /
            </button>
          </div>
          <div
            class="p-1"
            :class="isToggleCalculator === true ? 'col-4 ' : 'col-8'"
          >
            <button
              @click="handleClickBargainNumpad('del')"
              class="btn btn-white"
            >
              <i class="fas fa-backspace"></i>
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        @click="handleClickBtnBargainCancel"
        type="button"
        class="btn btn-cancel"
        data-bs-dismiss="modal"
      >
        ยกเลิก
      </button>
      <button
        @click="handleClickBtnBargainConfirm"
        type="button"
        class="btn btn-confirm"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#confirmModal"
        :disabled="
          bargainRedString !== '-' &&
          bargainBlueString !== '-' &&
          isBargainRedPass &&
          isBargainBluePass
            ? false
            : true
        "
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
  <!-- modal confirm -->
  <Modal bgcolor="grey" id="confirmModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 py-2 px-1 text-center">
            <span v-if="selectPriceColor === 'red'" class="bold-text"
              >แดงต่อ {{ bargainRedString }}</span
            >
            <span v-else class="bold-text">แดงรอง {{ bargainRedString }}</span>
          </div>
          <div class="col-6 py-2 px-1 text-center">
            <span v-if="selectPriceColor === 'blue'" class="bold-text"
              >น้ำเงินต่อ {{ bargainBlueString }}</span
            >
            <span v-else class="bold-text"
              >น้ำเงินรอง {{ bargainBlueString }}</span
            >
          </div>
          <div class="col-6 py-1 px-2">
            <button
              @click="handleClickBtnShowRetain('red')"
              class="btn btn-red pricebox confirm"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#retainModal"
            >
              {{ numberWithCommas(retainRedString, 2) }}
            </button>
          </div>
          <div class="col-6 py-1 px-2">
            <button
              @click="handleClickBtnShowRetain('blue')"
              class="btn btn-blue pricebox confirm"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#retainModal"
            >
              {{ numberWithCommas(retainBlueString, 2) }}
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button v-if="isBargainDB === true" type="button" class="btn btn-time">
        <i class="fas fa-history"></i>
        {{
          Object.keys(roundPrice).length !== 0 &&
          roundPrice.round_prices[roundIndex] !== undefined
            ? roundPrice.round_prices[roundIndex].ticket_delay_time
            : 0
        }}
      </button>
      <button
        @click="handleClickBtnConfirmCancel"
        type="button"
        class="btn btn-cancel"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#calculateModal"
      >
        ยกเลิก
      </button>
      <button
        @click="handleClickSubmitPrice"
        type="button"
        class="btn btn-confirm"
        id="btnSubmitPrice"
        :data-bs-dismiss="[
          retainRedString !== '-' && retainBlueString !== '-' ? 'modal' : '',
        ]"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
  <!-- modal retain -->
  <Modal bgcolor="pink" id="retainModal">
    <template #header>ราคาอั้น</template>

    <template #content>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-1 custom-tooltip">
            <input
              v-if="isRetainRed"
              v-model="retainRedString"
              class="form-control retain-input"
              type="text"
              :placeholder="
                retainRedString !== '-' ? retainRedString : 'ใส่ราคาอั้น'
              "
              disabled
            />
            <input
              v-else
              v-model="retainBlueString"
              class="form-control retain-input"
              type="text"
              :placeholder="
                retainBlueString !== '-' ? retainBlueString : 'ใส่ราคาอั้น'
              "
              disabled
            />
            <div
              v-if="
                isRetainRed &&
                retainRedString !== '-' &&
                parseInt(retainRedString) < 0
              "
              class="tooltoptext"
            >
              กรุณากรอกให้ถูกต้อง
            </div>
            <div
              v-if="
                isRetainBlue &&
                retainBlueString !== '-' &&
                parseInt(retainBlueString) < 0
              "
              class="tooltoptext"
            >
              กรุณากรอกให้ถูกต้อง
            </div>
          </div>
        </div>
        <div class="row">
          <div
            v-for="(history, index) in retainHistory"
            :key="index"
            class="history-div p-1"
          >
            <button
              @click="handleClickRetainNumpad(history, true)"
              class="btn btn-white p-1"
            >
              <i class="fas fa-history"></i> {{ numberWithCommas(history, 2) }}
            </button>
          </div>
        </div>
        <div class="row">
          <div v-for="number in retainArr" :key="number" class="col-4 p-1">
            <button
              @click="handleClickRetainNumpad(number)"
              class="btn btn-white"
            >
              {{ number }}
            </button>
          </div>
          <div class="col-4 p-1">
            <button
              @click="handleClickRetainNumpad('del')"
              class="btn btn-white"
            >
              <i class="fas fa-backspace"></i>
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        @click="handleClickBtnRetainCancel"
        type="button"
        class="btn btn-cancel"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        :data-bs-target="
          isSelectPriceType === 'upper' ? '#confirmModal' : '#drawModal'
        "
      >
        ยกเลิก
      </button>

      <button
        v-if="isRetainRed"
        type="button"
        class="btn btn-confirm"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        :data-bs-target="
          isSelectPriceType === 'upper' ? '#confirmModal' : '#drawModal'
        "
        :disabled="retainRedString !== '-' ? false : true"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
      <button
        v-else-if="isRetainBlue"
        type="button"
        class="btn btn-confirm"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        :data-bs-target="
          isSelectPriceType === 'upper' ? '#confirmModal' : '#drawModal'
        "
        :disabled="retainBlueString !== '-' ? false : true"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
  <Modal :bgcolor="isDBFound === true ? 'grey' : 'red'" id="drawModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid">
        <div v-if="isDBFound === false" class="text-center">
          <span class="bold-text" style="color: #fff">{{
            isSelectPriceType === "draw"
              ? "ไม่พบราคาเสมอในตาราง"
              : "ไม่พบราคาต่อรองในตาราง"
          }}</span>
        </div>
        <div v-else class="row">
          <div class="col-12 py-2 px-1 text-center">
            <span
              v-if="isSelectPriceType === 'draw' && selectPriceColor === 'red'"
              class="bold-text"
              >ใส่ราคาอั้นแดงเสมอ</span
            >
            <span
              v-else-if="
                isSelectPriceType === 'draw' && selectPriceColor === 'blue'
              "
              class="bold-text"
              >ใส่ราคาอั้นน้ำเงินเสมอ</span
            >
            <span v-else class="bold-text">ใส่ราคาอั้นต่อรอง</span>
          </div>
          <div class="col-6 py-1 px-2">
            <button
              @click="handleClickBtnShowRetain('red')"
              class="btn btn-red pricebox confirm"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#retainModal"
            >
              {{ numberWithCommas(retainRedString, 2) }}
            </button>
          </div>
          <div class="col-6 py-1 px-2">
            <button
              @click="handleClickBtnShowRetain('blue')"
              class="btn btn-blue pricebox confirm"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#retainModal"
            >
              {{ numberWithCommas(retainBlueString, 2) }}
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        @click="handleClickBtnBargainCancel"
        id="cancelBtn"
        type="button"
        class="btn btn-cancel"
        data-bs-dismiss="modal"
      >
        <span>{{ isDBFound === true ? "ยกเลิก" : "ตกลง" }}</span>
      </button>
      <button
        v-if="isDBFound === true"
        @click="handleClickSubmitPrice"
        type="button"
        class="btn btn-confirm"
        id="btnSubmitPrice2"
        :data-bs-dismiss="[
          retainRedString !== '-' && retainBlueString !== '-' ? 'modal' : '',
        ]"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
  <Modal bgcolor="grey" id="delayPrice" close="true">
    <template #header></template>

    <template #content>
      <div class="container-fluid">
        <div class="row body-text">
          <div class="text-center">
            {{
              isClosePrice === true
                ? "ต้องการปิดราคา กรุณารอ"
                : "ต้องการเปลื่ยนราคา กรุณารอ"
            }}
          </div>
          <div class="text-center mt-3">
            {{ numberWithCommas(timeLeft) }} วินาที
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        v-if="isSelectOnTable === false && isSelectPriceType !== 'bar'"
        @click="handleClickConfirmDelay"
        type="button"
        class="btn btn-red"
        data-bs-dismiss="modal"
      >
        {{ isClosePrice === true ? "ยืนยันปิดราคา" : "ยืนยันเปลื่ยนราคา" }}
      </button>
      <button
        v-else
        type="button"
        class="btn btn-red"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        :data-bs-target="
          isSelectPriceType === 'bar' ? '#drawModal' : '#confirmModal'
        "
      >
        ยืนยันเปลื่ยนราคา
      </button>
    </template>
  </Modal>
</template>

<script>
import { toRefs } from "@vue/reactivity";
import { usePrice } from "./composable/use-control";
import { utilily } from "@/utilities/utility";
import Modal from "@/components/Modal.vue";
import RoundPrice from "@/components/RoundPrice.vue";

export default {
  name: "ControlPrice",
  components: {
    Modal,
    RoundPrice,
  },
  setup() {
    const {
      state,
      handleClickBtnUpper,
      handleClickBtnDraw,
      handleClickBtnBar,
      handleClickBtnBargainCancel,
      handleClickBtnBargainConfirm,
      handleClickBtnRedPrice,
      handleClickBtnBluePrice,
      handleClickBargainNumpad,
      handleClickBtnConfirmCancel,
      handleClickBtnShowBargain,
      handleClickRetainNumpad,
      handleClickBtnShowRetain,
      handleClickBtnRetainCancel,
      handleClickSubmitPrice,
      handleClickBtnClosePrice,
      handleClickBtnGetBill,
      checkPrice,
      handleClickTable,
      handleClickChangePrice,
      handleClickSetPause,
      handleClickConfirmDelay,
      handleClickCalculator,
      handleClickVideo,
    } = usePrice();
    const { numberWithCommas, calculatePercentageReward } = utilily();
    return {
      ...toRefs(state),
      handleClickBtnUpper,
      handleClickBtnDraw,
      handleClickBtnBar,
      handleClickBtnBargainCancel,
      handleClickBtnBargainConfirm,
      handleClickBtnRedPrice,
      handleClickBtnBluePrice,
      handleClickBargainNumpad,
      handleClickBtnConfirmCancel,
      handleClickBtnShowBargain,
      handleClickRetainNumpad,
      handleClickBtnShowRetain,
      handleClickBtnRetainCancel,
      handleClickSubmitPrice,
      handleClickBtnClosePrice,
      handleClickBtnGetBill,
      checkPrice,
      numberWithCommas,
      calculatePercentageReward,
      handleClickTable,
      handleClickChangePrice,
      handleClickSetPause,
      handleClickConfirmDelay,
      handleClickCalculator,
      handleClickVideo,
    };
  },
};
</script>

<style lang="scss" scoped></style>
