<template>
  <Modal bgcolor="grey" id="rankingModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 pt-2 pb-4 px-1 text-center">
            <span class="ranking-header">Ranking</span>
          </div>
          <div class="col-12 p-2">
            <button
              @click="handleClickChangeRank('S')"
              class="btn btn-ranking h-100"
              :class="priceInfo.rank_price === 'S' ? 'active' : ''"
              data-bs-dismiss="modal"
            >
              S
            </button>
          </div>
          <div class="col-6 p-2">
            <button
              @click="handleClickChangeRank('A')"
              class="btn btn-ranking h-100"
              :class="priceInfo.rank_price === 'A' ? 'active' : ''"
              data-bs-dismiss="modal"
            >
              A
            </button>
          </div>
          <div class="col-6 p-2">
            <button
              @click="handleClickChangeRank('B')"
              class="btn btn-ranking h-100"
              :class="priceInfo.rank_price === 'B' ? 'active' : ''"
              data-bs-dismiss="modal"
            >
              B
            </button>
          </div>
          <div class="col-6 p-2">
            <button
              @click="handleClickChangeRank('C')"
              class="btn btn-ranking h-100"
              :class="priceInfo.rank_price === 'C' ? 'active' : ''"
              data-bs-dismiss="modal"
            >
              C
            </button>
          </div>
          <div class="col-6 p-2">
            <button
              @click="handleClickChangeRank('D')"
              class="btn btn-ranking h-100"
              :class="priceInfo.rank_price === 'D' ? 'active' : ''"
              data-bs-dismiss="modal"
            >
              D
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #footer> </template>
  </Modal>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { setRank } from "@/services/price-service";
import Modal from "@/components/Modal.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  components: {
    Modal,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      priceInfo: computed(() => store.state.priceModule.priceInfo),
    });
    const handleClickChangeRank = async (event) => {
      var data = {
        id: route.params.id,
        rank_price: event,
      };
      const response = await setRank(data);
      if (response.status) {
        console.log(response.status);
      }
    };

    return {
      ...toRefs(state),
      handleClickChangeRank,
    };
  },
};
</script>

<style lang="scss" scoped></style>
