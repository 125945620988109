import {
  getGamePrice,
  getGamePriceInfo,
  getRoundPrice,
  getPlaybackList,
  getPlaybackURL,
  getNameLists,
  putNameLists,
  getGameStat,
} from "@/services/price-service";

const priceModule = {
  namespaced: true,
  state: {
    price: {},
    priceInfo: {},
    priceType: null,
    playbackList: [],
    playbackURL: {},
    playbackId: null,
    roundPrice: {},
    selectPrice: {},
    isVideo: false,
    tabNum: "ControlIndex",
    nameLists: [],
    gameSelect: [],
    gameStat: [],
  },
  mutations: {
    SET_PLAYBACKID_DATA(state, newId) {
      state.playbackId = newId;
    },
    SET_PRICE_DATA(state, newPrice) {
      console.log(newPrice);
      state.price = newPrice;
    },
    SET_PRICEINFO_DATA(state, newPriceInfo) {
      state.priceInfo = newPriceInfo;
    },
    SET_PRICETYPE_DATA(state, newpriceType) {
      state.priceType = newpriceType;
    },
    SET_ROUNDPRICE_DATA(state, newRoundPrice) {
      state.roundPrice = newRoundPrice;
    },
    SET_SELECTPRICE_DATA(state, newSelectPrice) {
      state.selectPrice = newSelectPrice;
    },
    SET_ISVIDEO_DATA(state, newIsVideo) {
      state.isVideo = newIsVideo;
    },
    SET_TABNUM_DATA(state, newTabNum) {
      state.tabNum = newTabNum;
    },
    SET_PLAYBACKLIST_DATA(state, newList) {
      state.playbackList = newList;
    },
    SET_PLAYBACKURL_DATA(state, newURL) {
      state.playbackURL = newURL;
    },
    SET_NAMELISTS_DATA(state, newNameLists) {
      state.nameLists = newNameLists;
    },
    SET_GAMESELECT_DATA(state, newGameSelect) {
      state.gameSelect = newGameSelect;
    },
    SET_GAMESTAT_DATA(state, newGameStat) {
      state.gameStat = newGameStat;
    },
  },
  actions: {
    setGameSelect(context, data) {
      context.commit("SET_GAMESELECT_DATA", data);
    },
    async getGameStat(context, id) {
      const response = await getGameStat(id);
      if (response.status === true) {
        var gameStat = response.data !== null ? response.data : [];
        console.log("getGameStat", gameStat);
        context.commit("SET_GAMESTAT_DATA", gameStat);
      }
    },
    async getNameLists(context, key) {
      const response = await getNameLists(key);
      if (response.status === true) {
        var nameLists = response.data !== null ? response.data : [];
        console.log("getNameLists", nameLists);
        context.commit("SET_NAMELISTS_DATA", nameLists);
      }
    },
    async putNameLists(context, data) {
      const response = await putNameLists(data.key, JSON.stringify(data.json));
      if (response.status === true) {
        console.log("putNameLists", data.json);
        context.commit("SET_NAMELISTS_DATA", data.json);
      }
    },
    async getPlaybackList(context, id) {
      const response = await getPlaybackList(id);
      if (response.status === true) {
        var newList = response.data;
        for (let index = 0; index < response.data.length; index++) {
          var date = new Date(newList[index].created_at * 1000);
          var resultDate = new Date(newList[index].result_at * 1000);
          newList[index].fullDate =
            ("0" + date.getDate()).slice(-2) +
            "-" +
            ("0" + date.getMonth() + 1).slice(-2) +
            "-" +
            String(date.getFullYear() + 543).slice(2) +
            " " +
            ("0" + date.getHours()).slice(-2) +
            ":" +
            ("0" + date.getMinutes()).slice(-2) +
            " - " +
            ("0" + resultDate.getHours()).slice(-2) +
            ":" +
            ("0" + resultDate.getMinutes()).slice(-2);
        }
        console.log("playbackList", newList);
        context.commit("SET_PLAYBACKLIST_DATA", response.data);
      }
    },
    async getPlaybackURL(context, id) {
      const response = await getPlaybackURL(id);
      if (response.status === true) {
        console.log("playbackURL", response.data);
        context.commit("SET_PLAYBACKURL_DATA", response.data);
      }
    },
    checkPrice(context, price) {
      if (!price.is_price_open) {
        context.commit("SET_PRICETYPE_DATA", null);
      } else if (price.home_price === "9/10" && price.away_price === "9/10") {
        context.commit("SET_PRICETYPE_DATA", "bar");
      } else if (
        (price.home_price === "8/10" && price.away_price === "10/10") ||
        (price.home_price === "10/10" && price.away_price === "8/10")
      ) {
        context.commit("SET_PRICETYPE_DATA", "draw");
      } else {
        context.commit("SET_PRICETYPE_DATA", "upper");
      }
    },
    async getPrice(context, id) {
      const response = await getGamePrice(id);
      if (response.status) {
        var newPrice = response.data;
        newPrice.bet_min = newPrice.bet_min / 100;
        newPrice.home_price_limit = newPrice.home_price_limit / 100;
        newPrice.away_price_limit = newPrice.away_price_limit / 100;
        newPrice.total_home_amount = newPrice.total_home_amount / 100;
        newPrice.total_away_amount = newPrice.total_away_amount / 100;
        var obj = newPrice.round_choice;
        var arr = [];
        var i = 0;
        for (var x in obj) {
          arr[i] = obj[x];
          i++;
        }
        arr.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        );
        newPrice.round_choice = arr;
        context.commit("SET_PRICE_DATA", newPrice);
        context.commit("SET_PRICEINFO_DATA", newPrice);
        this.dispatch("priceModule/checkPrice", newPrice);
      }
    },
    async getPriceInfo(context, id) {
      const response = await getGamePriceInfo(id);
      if (response.status) {
        var newPriceInfo = response.data;
        newPriceInfo.bet_min = newPriceInfo.bet_min / 100;
        newPriceInfo.home_price_limit = newPriceInfo.home_price_limit / 100;
        newPriceInfo.away_price_limit = newPriceInfo.away_price_limit / 100;
        newPriceInfo.total_home_amount = newPriceInfo.total_home_amount / 100;
        newPriceInfo.total_away_amount = newPriceInfo.total_away_amount / 100;
        if (newPriceInfo.home_price && newPriceInfo.away_price) {
          const ArrHome = newPriceInfo.home_price.split("/");
          const ArrAway = newPriceInfo.away_price.split("/");
          newPriceInfo.bargain = "";
          if (ArrHome[1] / ArrHome[0] > 1) {
            newPriceInfo.bargain = "home";
          } else if (ArrAway[1] / ArrAway[0] > 1) {
            newPriceInfo.bargain = "away";
          }
        }

        console.log("getPriceInfo", newPriceInfo);
        this.dispatch("priceModule/checkPrice", newPriceInfo);
        context.commit("SET_PRICEINFO_DATA", newPriceInfo);
      }
    },
    setPrice(context, newPrice) {
      context.commit("SET_PRICE_DATA", newPrice);
    },
    async getRoundPrice(context, item) {
      var data;
      if (item.rank === null) {
        data = {
          game_price_id: parseInt(item.id),
        };
      } else {
        data = {
          game_price_id: parseInt(item.id),
          rank_price: item.rank,
        };
      }
      const response = await getRoundPrice(data);
      console.log("Round", response);
      var newRoundPrice = response.data;
      context.commit("SET_ROUNDPRICE_DATA", newRoundPrice);
    },
    setRoundPrice(context, newRoundPrice) {
      context.commit("SET_ROUNDPRICE_DATA", newRoundPrice);
    },
    setSelectPrice(context, newSelectPrice) {
      context.commit("SET_SELECTPRICE_DATA", newSelectPrice);
    },
    setIsVideo(context, newIsVideo) {
      context.commit("SET_ISVIDEO_DATA", newIsVideo);
    },
    setPlaybackId(context, newPlaybackId) {
      context.commit("SET_PLAYBACKID_DATA", newPlaybackId);
    },
    setTabNum(context, newTabNum) {
      context.commit("SET_TABNUM_DATA", newTabNum);
    },
  },
};

export default priceModule;
