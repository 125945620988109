<template>
  <Modal bgcolor="grey" id="TeamStatModal">
    <template #header>
      <div class="position-relative">
        <div
          class="color-box"
          :class="stat.side === 'home' ? 'bg-red' : 'bg-blue'"
        ></div>
        <div class="text-truncate ps-2" style="font-size: 1.15rem">
          {{ stat.name }}
        </div>
      </div>
      <button
        type="button"
        class="btn-close result-header m-0"
        data-bs-dismiss="modal"
      ></button>
    </template>

    <template #content>
      <div class="container-fluid">
        <div
          class="d-flex justify-content-evenly align-items-center fonts-medium h4"
        >
          <div>ชนะ {{ stat.win }}</div>
          <div>เสมอ {{ stat.draw }}</div>
          <div>แพ้ {{ stat.lose }}</div>
        </div>
      </div>
    </template>

    <template #footer></template>
  </Modal>
</template>

<script>
import { reactive, toRefs } from "vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  props: ["stat"],
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
