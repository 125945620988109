<template>
  <Modal bgcolor="grey" id="NewModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid" style="margin-top: -1rem">
        <div class="d-flex justify-content-center position-relative">
          <div class="semibold-20 text-black">สร้างเกมใหม่</div>
          <button
            @click="setSelectIndex(null, league_id)"
            type="button"
            class="btn btn-confirm position-absolute top-0 end-0"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#addGameModal"
          >
            <i class="fas fa-plus-circle"></i> เพิ่ม
          </button>
        </div>
        <div
          class="overflow-auto mt-4"
          style="max-height: 400px; scrollbar-width: thin"
        >
          <div
            @click="setSelectIndex(null, league_id)"
            class="new-game-list text-center cursor-pointer"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#confirmNewGameModal"
          >
            Auto
          </div>
          <div v-for="n in 1000" :key="n">
            <div
              v-if="nameLists[n - 1] !== undefined"
              @click="setSelectIndex(n - 1, league_id)"
              class="new-game-list my-2 cursor-pointer"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#confirmNewGameModal"
            >
              <div class="list-name position-relative">
                <div class="position-relative">
                  <div class="color-box bg-red"></div>
                  <div class="text-truncate ps-2">
                    {{
                      nameLists[n - 1] !== undefined
                        ? nameLists[n - 1].home_name
                        : ""
                    }}
                  </div>
                </div>
                <div class="position-relative">
                  <div class="color-box bg-blue"></div>
                  <div class="text-truncate ps-2">
                    {{
                      nameLists[n - 1] !== undefined
                        ? nameLists[n - 1].away_name
                        : ""
                    }}
                  </div>
                </div>
                <div
                  @click="setSelectIndex(n - 1, league_id)"
                  class="position-absolute top-50 end-0 translate-middle-y cursor-pointer"
                  style="color: #ff8f8f"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#confirmDeleteModal"
                >
                  <i class="fas fa-minus-circle"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer> </template>
  </Modal>
  <Modal bgcolor="grey" id="addGameModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid">
        <div class="text-center">
          <span class="semibold-20 text-black"> เพิ่มเกม </span>
        </div>
        <div
          class="radio-check d-flex justify-content-around align-items-center fonts-medium"
        >
          <div class="form-check">
            <input
              v-model="radioCheck"
              class="form-check-input"
              type="radio"
              value="list"
              checked
            />
            <label class="form-check-label" for="flexRadioDefault1">
              เลือกจากรายการ
            </label>
          </div>
          <div class="form-check">
            <input
              v-model="radioCheck"
              class="form-check-input"
              type="radio"
              value="new"
            />
            <label class="form-check-label" for="flexRadioDefault2">
              สร้างชื่อใหม่
            </label>
          </div>
        </div>
        <div>
          <div class="input-group mt-3 form-list position-relative">
            <div
              class="search-box cursor-pointer"
              :class="searchResult.home_name.length > 0 ? 'show' : ''"
            >
              <div
                v-for="(item, index) in searchResult.home_name"
                :key="index"
                @click="handleClickSearchResult('home_name', item)"
                class="name-list"
              >
                {{ item }}
              </div>
            </div>
            <span
              class="input-group-text border-0 red-input position-relative px-3"
              id="basic-addon1"
            >
              <div class="color-box bg-red" style="left: auto; top: auto"></div>
            </span>
            <input
              @input="handleInputText('home_name')"
              v-model="home_name"
              type="text"
              class="form-control border-0 red-input"
              placeholder="ฝั่งเเดง"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="input-group mt-2 form-list position-relative">
            <div
              class="search-box cursor-pointer"
              :class="searchResult.away_name.length > 0 ? 'show' : ''"
            >
              <div
                v-for="(item, index) in searchResult.away_name"
                :key="index"
                @click="handleClickSearchResult('away_name', item)"
                class="name-list"
              >
                {{ item }}
              </div>
            </div>
            <span
              class="input-group-text border-0 blue-input position-relative px-3"
              id="basic-addon2"
            >
              <div
                class="color-box bg-blue"
                style="left: auto; top: auto"
              ></div>
            </span>
            <input
              @input="handleInputText('away_name')"
              v-model="away_name"
              type="text"
              class="form-control border-0 blue-input"
              placeholder="ฝั่งน้ำเงิน"
              aria-label="Username"
              aria-describedby="basic-addon2"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="btn btn-cancel"
        style="padding: 0 15px !important"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#NewModal"
      >
        ยกเลิก
      </button>
      <button
        @click="handleClickAddList()"
        type="button"
        class="btn btn-confirm"
        :disabled="
          home_name === away_name
            ? true
            : gameSelect.findIndex((x) => x === home_name) !== -1 &&
              gameSelect.findIndex((x) => x === away_name) !== -1
            ? false
            : radioCheck === 'new'
            ? false
            : true
        "
        style="padding: 0 15px !important"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#NewModal"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
  <Modal bgcolor="grey" id="confirmNewGameModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid">
        <div class="text-center">
          <span class="semibold-20 text-black"> ยืนยันสร้างเกมใหม่ </span>
        </div>

        <div
          v-if="selectIndex !== null"
          class="new-game-list mt-3 cursor-pointer"
        >
          <div class="list-name">
            <div class="position-relative">
              <div class="color-box bg-red"></div>
              <div class="text-truncate">
                {{ nameLists[selectIndex].home_name }}
              </div>
            </div>
            <div class="position-relative">
              <div class="color-box bg-blue"></div>
              <div class="text-truncate">
                {{ nameLists[selectIndex].away_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="btn btn-cancel"
        style="padding: 0 15px !important"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#NewModal"
      >
        ยกเลิก
      </button>
      <button
        @click="handleClickNewGame"
        type="button"
        class="btn btn-confirm"
        style="padding: 0 15px !important"
        data-bs-dismiss="modal"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
  <Modal bgcolor="grey" id="confirmDeleteModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid text-center">
        <span class="semibold-20 text-black">ยืนยันลบเกม</span>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="btn btn-cancel"
        style="padding: 0 15px !important"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#NewModal"
      >
        ยกเลิก
      </button>
      <button
        @click="handleClickDeleteList()"
        type="button"
        class="btn btn-confirm"
        style="padding: 0 15px !important"
        data-bs-dismiss="modal"
        data-bs-toggle="modal"
        data-bs-target="#NewModal"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import Modal from "@/components/Modal.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { setGameStatus, setNewGame } from "@/services/price-service";

export default {
  components: {
    Modal,
  },
  props: ["league_id"],
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      searchResult: { home_name: [], away_name: [] },
      price: computed(() => store.state.priceModule.price),
      nameLists: computed(() => store.state.priceModule.nameLists),
      gameSelect: computed(() => store.state.priceModule.gameSelect),
      selectIndex: null,
      league_id: 0,
      home_name: "",
      away_name: "",
      radioCheck: null,
      isLoading: false,
    });
    const handleInputText = (side) => {
      state.searchResult[side] = performSearch(state[side]);
    };
    const performSearch = (searchTerm) => {
      // Code to perform the search using the passed in search term
      if (searchTerm === "" || state.radioCheck === "new") {
        return [];
      }
      console.log("Searching for: " + searchTerm);
      // Perform the search
      // Example: searching in an array of objects
      let result = state.gameSelect.filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return result;
      // Do something with the search results
    };
    const handleClickSearchResult = (side, name) => {
      state[side] = name;
      state.searchResult[side] = [];
    };
    const handleClickAddList = async () => {
      try {
        state.isLoading = true;
        if (
          state.home_name !== "" &&
          state.away_name !== "" &&
          state.league_id !== 0 &&
          state.league_id !== undefined &&
          state.league_id !== null
        ) {
          console.log(
            "Add list",
            state.league_id,
            state.home_name,
            state.away_name
          );
          var data = JSON.parse(JSON.stringify(state.nameLists));
          data.push({
            home_name: state.home_name,
            away_name: state.away_name,
          });
          console.log("Add list", state.nameLists, data);
          if (data.length === 1) {
            const key = "matchLists_L" + state.league_id;
            await store.dispatch("priceModule/getNameLists", key);
            if (state.nameLists.length === 0) {
              store.dispatch("priceModule/putNameLists", {
                key: "matchLists_L" + state.league_id,
                json: data,
              });
            }
          } else if (data.length > 1) {
            store.dispatch("priceModule/putNameLists", {
              key: "matchLists_L" + state.league_id,
              json: data,
            });
          }
          // localStorage.setItem("nameLists", JSON.stringify(state.nameLists));
        }
      } finally {
        state.isLoading = false;
        state.home_name = "";
        state.away_name = "";
      }
    };
    const handleClickDeleteList = async () => {
      if (
        state.selectIndex !== null &&
        state.league_id !== 0 &&
        state.league_id !== undefined &&
        state.league_id !== null
      ) {
        var data = JSON.parse(JSON.stringify(state.nameLists));
        data.splice(state.selectIndex, 1);
        console.log("Delete list", state.nameLists, data);
        if (data.length === 0) {
          const key = "matchLists_L" + state.league_id;
          await store.dispatch("priceModule/getNameLists", key);
          if (state.nameLists.length === 1) {
            store.dispatch("priceModule/putNameLists", {
              key: "matchLists_L" + state.league_id,
              json: data,
            });
          }
        } else if (data.length > 0) {
          store.dispatch("priceModule/putNameLists", {
            key: "matchLists_L" + state.league_id,
            json: data,
          });
        }
      }
      state.selectIndex = null;
    };
    const handleClickNewGame = async () => {
      try {
        state.isLoading = true;
        var data;
        if (state.selectIndex !== null) {
          data = {
            league_id: state.league_id,
            home_name: state.nameLists[state.selectIndex].home_name,
            away_name: state.nameLists[state.selectIndex].away_name,
          };
        } else {
          data = { league_id: state.league_id };
        }
        const response = await setNewGame(data);
        console.log("Set NewGame", state.league_id, response);
        if (response.status === true) {
          if (state.selectIndex !== null) {
            handleClickDeleteList();
          }

          const data = { id: response.data.id, status: "live" };
          const response2 = await setGameStatus(data);
          if (response2.status) {
            console.log(response2);
          }

          if (route.name !== "Match") {
            await router.replace({
              name: "ControlIndex",
              params: { id: response.data.id },
            });
            router.go();
          } else {
            await router.replace({
              name: "ControlIndex",
              params: { id: response.data.id },
            });
          }
        }
      } finally {
        state.isLoading = false;
      }
    };
    const setSelectIndex = (index, id) => {
      state.selectIndex = index;
      state.league_id = id;
    };
    return {
      ...toRefs(state),
      handleInputText,
      handleClickSearchResult,
      handleClickAddList,
      handleClickNewGame,
      handleClickDeleteList,
      setSelectIndex,
    };
  },
};
</script>

<style lang="scss" scoped></style>
