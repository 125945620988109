<template>
  <div class="container">
    <div class="row py-5">
      <div class="d-flex justify-content-center">
        <img class="w-60" alt="" src="@/assets/images/Logo-White.png" />
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <span class="semibold-20">STREAMER CONTROL</span>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <div class="d-flex align-items-center justify-content-center my-4">
          <span class="semibold-20">เข้าสู่ระบบ</span>
        </div>
        <form @submit.prevent="onSubmit">
          <div class="d-flex justify-content-center position-relative mb-3">
            <input
              class="form-control login-input"
              id="inputUsername"
              type="text"
              placeholder="ชื่อผู้ใช้งาน"
              v-model="userState.username"
            />
          </div>
          <div class="d-flex justify-content-center position-relative mb-3">
            <input
              class="form-control login-input"
              id="inputPassword"
              type="password"
              placeholder="รหัสผ่าน"
              v-model="userState.password"
            />
            <div class="position-absolute icon-eye" @click="handleClickEye">
              <div v-if="!showPassword"><i class="fas fa-eye"></i></div>
              <div v-else><i class="fas fa-eye-slash"></i></div>
              <!-- <fa v-if="!showPassword" icon="eye" />
              <fa v-else icon="eye-slash" /> -->
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center mt-5 mb-0"
          >
            <button class="btn btn-primary btn-login" type="submit">
              เข้าสู่ระบบ
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { useLogin } from "./composable/use-login";

export default {
  name: "Login",
  setup() {
    const { state, handleClickEye, onSubmit } = useLogin();
    return { ...toRefs(state), handleClickEye, onSubmit };
  },
};
</script>

<style lang="scss" scoped></style>
