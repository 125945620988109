<template>
  <div class="main-carousel">
    <div
      v-for="(round, index) in price.round_choice"
      :key="index"
      class="carousel-cell"
      :class="[
        priceInfo.round_id === null && round.position === 1
          ? 'is-initial-select'
          : '',
        priceInfo.round_id === round.id ? 'is-initial-select' : '',
      ]"
      :id="round.id"
    >
      <button
        class="btn btn-round"
        :class="[
          priceInfo.round_id === null && round.position === 1 ? 'active' : '',
          priceInfo.round_id === round.id ? 'active' : '',
        ]"
        @click="handleClickBtnRound($event, round.id)"
      >
        <span class="color-round"></span>
        {{ round.name }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onUpdated } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import "flickity/css/flickity.css";
import Flickity from "flickity";
import { swalConfirm1 } from "@/utilities/sweet-alert.js";
import { setGameRound } from "@/services/price-service";
import { useIndex } from "@/views/control/composable/use-control";

export default {
  name: "Flickity",
  props: ["price"],
  setup(props) {
    const { swalCf1 } = swalConfirm1();
    const { updateMainPage } = useIndex();
    const store = useStore();
    const route = useRoute();
    const priceInfo = computed(() => store.state.priceModule.priceInfo);
    onUpdated(() => {
      new Flickity(".main-carousel", {
        initialIndex: ".is-initial-select",
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
      });
    });
    const handleClickBtnRound = (event, id) => {
      var price = props.price;
      if (event.target.classList.contains("active")) {
        return;
      }
      swalCf1
        .fire({
          buttonsStyling: false,
          showCancelButton: true,
          reverseButtons: true,
          html: "<span style='color:#000'>ยืนยันการเปลี่ยนยก</span>",
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "<i class='fas fa-check-circle'></i> ยืนยัน",
          confirmButtonColor: "#ffffff",
          background: "#e5e5e5",
        })
        .then(async (isConfirm) => {
          if (isConfirm.isConfirmed) {
            const data = JSON.parse(
              `{"id":${route.params.id}, "round_id":${id}}`
            );
            const response = await setGameRound(data);
            if (response.status) {
              price.round_id = id;
              updateMainPage(route, store, true);
              store.dispatch("priceModule/setPrice", price);
              store.dispatch("priceModule/setRoundPrice", {});
            }
          }
        });
    };
    return { priceInfo, handleClickBtnRound };
  },
};
</script>

<style lang="scss" scoped></style>
