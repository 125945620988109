<template>
  <div class="container bg-grey position-relative">
    <Header
      :match="null"
      back="Login"
      @handleClickRefresh="handleClickRefresh"
    />
    <div class="dialog-loggedin" id="dialog-loggedin">
      <div class="text-center">
        <i class="fas fa-check-circle"></i> ลงชื่อเข้าใช้เรียบร้อย
      </div>
    </div>
    <div v-if="matchlists.length <= 0" class="no-match">ยังไม่มีรายการ</div>
    <div v-else class="accordion" id="accordionMatch">
      <div
        v-for="(matchlist, i) in matchlists"
        :key="i"
        class="accordion-item py-2"
      >
        <h2 class="accordion-header" :id="`heading${i}`">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${i}`"
            aria-expanded="true"
            :aria-controls="`collapse${i}`"
          >
            <img
              v-if="matchlist.league_image"
              class="accordion-header-img"
              :src="matchlist.league_image"
              alt=""
            />
            {{ matchlist.name }}&emsp;{{ matchlist.fullDate }}
          </button>
        </h2>
        <div
          :id="`collapse${i}`"
          class="accordion-collapse"
          :class="i > 0 ? 'collapse' : 'collapse show'"
          :aria-labelledby="`heading${i}`"
          data-bs-parent="#accordionMatch"
        >
          <div class="accordion-body">
            <div v-if="matchlist.can_new_game === false" class="match-time">
              <div class="badge badge-time">
                {{ matchlist.time_start }} - {{ matchlist.time_end }} น.
              </div>
            </div>
            <div
              v-if="
                matchlist.can_new_game === true &&
                matchlist.game_list.length <= 0
              "
              class="my-2 text-center"
            >
              <button
                @click="handleClickNewGame(matchlist.league_id)"
                type="button"
                class="btn btn-white w-50"
                data-bs-toggle="modal"
                data-bs-target="#NewModal"
              >
                สร้างเกมใหม่
              </button>
            </div>
            <div
              v-for="(gamelist, j) in matchlist.game_list"
              :key="j"
              @click="handleClickMatch(gamelist.id, gamelist.game_id)"
              class="match-item my-3"
              :class="[
                gamelist.id === null ? 'opacity-50' : '',
                gamelist.status === 'live' ? 'live' : '',
              ]"
            >
              <div
                v-if="
                  (gamelist.round_id || gamelist.result !== 'wait') &&
                  gamelist.result !== null
                "
                class="match-number active"
              >
                <div class="match-number-active">
                  คู่ที่
                  {{
                    matchlist.can_new_game === true
                      ? gamelist.id
                      : gamelist.position
                  }}
                </div>
                <div
                  class="match-number-active"
                  :class="[
                    gamelist.result === 'home' ? 'text-white bg-red' : '',
                    gamelist.result === 'away' ? 'text-white bg-blue' : '',
                  ]"
                >
                  <div
                    v-if="
                      gamelist.result !== null && gamelist.result !== 'wait'
                    "
                    class="w-100 text-center"
                  >
                    <span v-if="gamelist.result === 'home'">แดงชนะ</span>
                    <span v-else-if="gamelist.result === 'away'">
                      น้ำเงินชนะ
                    </span>
                    <span v-else-if="gamelist.result === 'draw'">เสมอ</span>
                    <span v-else>ยกเลิก</span>
                  </div>
                  <div v-else>
                    <span class="color-round"></span>&nbsp;{{
                      gamelist.round_text
                    }}
                  </div>
                </div>
              </div>
              <div v-else class="match-number">
                <div class="match-number-text">
                  คู่ที่ {{ gamelist.position }}
                </div>
              </div>
              <div class="match-name">
                <div class="position-relative">
                  <div class="color-box bg-red"></div>
                  <div class="text-truncate">{{ gamelist.home_name }}</div>
                </div>
                <div class="position-relative">
                  <div class="color-box bg-blue"></div>
                  <div class="text-truncate">{{ gamelist.away_name }}</div>
                </div>
              </div>
              <div v-if="gamelist.status === 'live'" class="live-icon">
                <LiveIcon :id="'lavContainer' + i + '_' + j" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-new-game :league_id="league_id" />
  </div>
</template>

<script>
import { toRefs } from "@vue/reactivity";
import Header from "@/components/Header.vue";
import LiveIcon from "@/components/LiveIcon.vue";
import { useMatch } from "./composable/use-match";
import ModalNewGame from "../../components/modal/ModalNewGame.vue";

export default {
  name: "Match",
  components: {
    Header,
    LiveIcon,
    ModalNewGame,
  },
  setup() {
    const { state, handleClickMatch, handleClickRefresh, handleClickNewGame } =
      useMatch();
    return {
      ...toRefs(state),
      handleClickMatch,
      handleClickRefresh,
      handleClickNewGame,
    };
  },
};
</script>

<style lang="scss" scoped></style>
