import { computed, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useIndex } from "@/views/control/composable/use-control";
import { getMatch } from "@/services/match-service";
import { gameInit } from "@/services/price-service";
import { swalConfirm1 } from "@/utilities/sweet-alert.js";
import { useStore } from "vuex";
import { utilily } from "../../../utilities/utility";

export function useMatch() {
  const { swalCf1 } = swalConfirm1();
  const router = useRouter();
  const store = useStore();
  const { handleClickCreateNewGame } = utilily();
  const state = reactive({
    price: computed(() => store.state.priceModule.price),
    league_id: 0,
    matchlists: [],
    fullMonths: [
      "มกราคม",
      "กุมภาพันธ์  ",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],
  });
  onMounted(() => {
    getMatchList();
    store.commit("priceModule/SET_PRICE_DATA", {});
  });
  const getMatchList = async () => {
    const isLoggedIn = localStorage.getItem("uid");
    if (!isLoggedIn) {
      return;
    }
    const response = await getMatch();
    console.log(response);
    state.matchlists = response.data;
    for (let index = 0; index < response.data.length; index++) {
      var date = new Date(state.matchlists[index].start_at * 1000);
      state.matchlists[index].fullDate =
        ("0" + date.getDate()).slice(-2) +
        " " +
        state.fullMonths[date.getMonth()] +
        " " +
        (date.getFullYear() + 543);
      if (state.matchlists.can_new_game === true) {
        state.matchlists[index].game_list.sort(
          (a, b) => parseFloat(b.id) - parseFloat(a.id)
        );
      }
    }
    console.log(state.matchlists);
  };
  const handleClickRefresh = () => {
    getMatchList();
  };
  const handleClickNewGame = async (id) => {
    console.log("League_id", id);
    state.league_id = id;
    handleClickCreateNewGame(id);
  };
  const handleClickMatch = async (id, gameId) => {
    if (id) {
      await router.push({ name: "ControlIndex", params: { id: id } });
    } else {
      swalCf1
        .fire({
          buttonsStyling: false,
          showCancelButton: true,
          reverseButtons: true,
          html: "ยืนยันการสร้างแมตช์",
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "<i class='fas fa-check-circle'></i> ยืนยัน",
          confirmButtonColor: "#ffffff",
          background: "#e5e5e5",
        })
        .then(async (isConfirm) => {
          if (isConfirm.isConfirmed) {
            const data = JSON.parse(`{"id":${gameId}}`);
            const response = await gameInit(data);
            if (response.status) {
              await router.push({
                name: "ControlIndex",
                params: { id: response.data.id },
              });
            } else {
              console.log("error");
            }
          }
        });
    }
  };
  return {
    state,
    handleClickMatch,
    handleClickRefresh,
    handleClickNewGame,
  };
}

export function showLoggedin() {
  const { addElementClass, removeElementClass } = useIndex();
  const isShowLoggedin = () => {
    var dialog;
    const dialogInv = setInterval(() => {
      dialog = document.querySelector("#dialog-loggedin");
      if (dialog !== null) {
        clearInterval(dialogInv);
        addElementClass(dialog, "animated");
        setTimeout(() => {
          removeElementClass(dialog, "animated");
        }, 4000);
      }
    }, 500);
  };
  return {
    isShowLoggedin,
  };
}
