import { h, resolveComponent } from "vue";
import ControlIndex from "@/views/control/ControlIndex.vue";
import ControlPrice from "@/views/control/ControlPrice.vue";
import ControlResult from "@/views/control/ControlResult.vue";
import ControlHistory from "@/views/control/ControlHistory.vue";

const controlRoutes = [
  {
    path: "",
    name: "ControlRoute",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: ":id",
        name: "ControlIndex",
        component: ControlIndex,
      },
      {
        path: ":id/price",
        name: "ControlPrice",
        component: ControlPrice,
      },
      {
        path: ":id/result",
        name: "ControlResult",
        component: ControlResult,
      },
      {
        path: ":id/history",
        name: "ControlHistory",
        component: ControlHistory,
      },
    ],
  },
];

export default controlRoutes;
