<template>
  <div v-show="isVideo === true" class="vp-container">
    <video id="videoPlayer" class="video-js vjs-fill" playsinline></video>
    <!-- <div class="position-relative live-div">
      <video
        id="videoPlayer"
        class="video-js vjs-16-9"
        style="object-fit: fill"
        playsinline
      ></video>
      <div class="live-btn h1">
        <div v-if="isPlay === false" @click="handleClickPlay(true)">
          <i class="fas fa-play-circle"></i>
        </div>
        <div v-else @click="handleClickPlay(false)" class="hover-pause">
          <i class="fas fa-pause-circle"></i>
        </div>
      </div>
    </div> -->

    <!-- <div v-show="playerType === 'WEBRTC'">
      <audio id="streamListener" playsinline autoplay></audio>
      <audio id="dealerListener" playsinline autoplay></audio>
    </div> -->
    <div class="row justify-content-end me-2 mb-2">
      <div class="col-1 px-0"><i class="fas fa-video"></i></div>
      <div class="col-3 px-0">
        {{
          price.additional
            ? price.additional.match_video_id
              ? price.additional.match_video_id
              : showId
            : showId
        }}
      </div>
      <div
        class="col-1 ps-0"
        data-bs-toggle="modal"
        data-bs-target="#confirmVideoModal"
      >
        <i class="fas fa-edit"></i>
      </div>
    </div>
  </div>
  <Modal bgcolor="grey" id="confirmVideoModal">
    <template #header></template>

    <template #content>
      <div class="container-fluid text-center">
        <span class="semibold-20 text-black">แก้ไข</span>
        <div class="input-group fonts-medium mt-2">
          <span class="input-group-text"> Video ID </span>
          <input v-model="videoId" type="text" class="form-control" />
        </div>
      </div>
    </template>

    <template #footer>
      <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
        ยกเลิก
      </button>
      <button
        @click="handleClickConfirmVideoId"
        type="button"
        class="btn btn-confirm"
        data-bs-dismiss="modal"
      >
        <i class="fas fa-check-circle"></i> ยืนยัน
      </button>
    </template>
  </Modal>
</template>

<script>
import { computed, onBeforeUnmount, reactive, toRefs, watch } from "vue";
import Modal from "@/components/Modal.vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { RoomEvent, Room, Track } from "livekit-client";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getLiveToken, setMatchVideo } from "@/services/price-service";

export default {
  components: {
    Modal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      price: computed(() => store.state.priceModule.price),
      isVideo: computed(() => store.state.priceModule.isVideo),
      isPlay: false,
      playerType: "",
      videoPlayer: null,
      videoId: "",
      showId: "-",
      wss_stream_url: null,
      room: undefined,
    });
    onBeforeUnmount(() => {
      store.dispatch("priceModule/setIsVideo", false);
      stopStream();
    });
    watch(
      () => [state.isVideo],
      ([isVideo], [previsVideo]) => {
        if (isVideo === true) {
          setVideoPlayer();
          console.log(previsVideo);
        } else {
          stopStream();
        }
      }
    );
    const handleClickConfirmVideoId = async () => {
      const data = {
        id: route.params.id,
        match_video_id: state.videoId,
      };
      console.log(data);
      const response = await setMatchVideo(data);
      if (response.status === true) {
        state.showId = state.videoId;
        // state.price.additional.match_video_id = state.videoId;
        // store.dispatch("priceModule/setVideoId", state.videoId);
      }
      console.log(response);
    };
    const setVideoPlayer = async () => {
      if (state.price.http_stream_url === null) {
        return;
      }
      if (state.price.http_stream_url.match(/^(https:\/\/)[\s\S]*/g)) {
        state.playerType = "VIDEOJS";
        setVideoJS();
      }
      if (state.price.http_stream_url.match(/^(wss:\/\/)[\s\S]*/g)) {
        state.playerType = "WEBRTC";
        state.wss_stream_url = state.price.http_stream_url;
        setWebRtcRoom();
        // await setVideoWebRTC();
      }
    };
    const setVideoJS = () => {
      const videoOptions = {
        autoplay: true,
        controls: true,
        muted: true,
        sources: [
          {
            // src: "https://www.w3schools.com/html/mov_bbb.mp4",
            // type: "video/mp4",
            src: state.price.http_stream_url,
            type: "application/x-mpegURL",
          },
        ],
      };
      const videoInv = setInterval(() => {
        const videoTag = document.querySelector(".vp-container > video");
        // console.log(videoTag);
        if (videoTag) {
          clearInterval(videoInv);
          // videoTag.style.width = "100%";
          // videoTag.style.height = "100%";
          state.videoPlayer = videojs(
            videoTag,
            videoOptions,
            function onPlayerReady() {
              console.log("onPlayerReady");
            }
          );
          state.videoPlayer.on("error", function () {
            console.log("vdo js error occured ", state.videoPlayer.error());
          });
          if (state.prevMod === "undefined") {
            state.videoPlayer.muted(false);
          }
        }
      }, 100);
    };
    const setWebRtcRoom = async () => {
      state.room = new Room();
      state.room
        .on(RoomEvent.SignalConnected, () => {
          console.log("connected! webrtc video");
          const streamerIdentity = `L${state.price.league_id}`;
          const dealerIdentity = `L${state.price.league_id}:GP${route.params.id}`;
          // get both participant (streamer and dealer)
          const streamer =
            state.room.getParticipantByIdentity(streamerIdentity);
          const dealer = state.room.getParticipantByIdentity(dealerIdentity);
          // get track(s) from both streamer has camera and microphone
          const streamerTracks =
            streamer !== undefined ? streamer.getTracks() : undefined;
          if (streamerTracks !== undefined) {
            streamerTracks.forEach((track) => {
              track.setSubscribed(true);
            });
          } else {
            console.log(
              `can't get streaming vid and sound of ${streamerIdentity}`
            );
          }

          // dealer has only microphone
          const dealerTracks =
            dealer !== undefined
              ? dealer.getTrack(Track.Source.Microphone)
              : undefined;
          if (dealerTracks !== undefined) {
            dealerTracks.setSubscribed(true);
          } else {
            console.log(
              `can't get streaming vid and sound of ${dealerIdentity}`
            );
          }
        })
        .on(RoomEvent.TrackPublished, (publication, participant) => {
          const streamerIdentity = `L${state.price.league_id}`;
          const dealerIdentity = `L${state.price.league_id}:GP${route.params.id}`;
          if (
            participant.identity === streamerIdentity ||
            participant.identity === dealerIdentity
          )
            publication.setSubscribed(true);
        })
        .on(RoomEvent.TrackSubscribed, (_track, _publication, participant) => {
          console.log("Participant Subscribed");
          renderParticipant(participant);
        })
        .on(RoomEvent.TrackUnpublished, (publication, participant) => {
          console.log("track unpublished");
          const streamerIdentity = `L${state.price.league_id}`;
          const dealerIdentity = `L${state.price.league_id}:GP${route.params.id}`;
          if (
            participant.identity === streamerIdentity ||
            participant.identity === dealerIdentity
          )
            publication.setSubscribed(false);
        })
        .on(RoomEvent.TrackUnsubscribed, (track, _, participant) => {
          console.log("track unsubscribed");
          const streamerIdentity = `L${state.price.league_id}`;
          const dealerIdentity = `L${state.price.league_id}:GP${route.params.id}`;
          // streamer got video and audio track
          if (participant.identity === streamerIdentity) {
            if (track.kind === "video") {
              const videoElm = document.querySelector(".vp-container > video");
              track.detach(videoElm);
              console.log(
                `detach participant ${participant.identity} from video element`
              );
            } else if (track.kind === "audio") {
              const audioELm = document.getElementById("dealerListener");
              state.attachedStreamListener = false;
              track.detach(audioELm);
              console.log(
                `detach participant ${participant.identity} from audio element`
              );
            }
          }
          // dealer got only audio track
          else if (participant.identity === dealerIdentity) {
            const audioELm = document.getElementById("streamListener");
            state.attachedDealerListener = false;
            track.detach(audioELm);
            console.log(
              `detach participant ${participant.identity} from audio element`
            );
          }
        });

      const listenerToken = await getLiveToken(
        state.price.league_id,
        route.params.id
      );
      const roomConnectOpt = {
        autoSubscribe: false,
      };
      const token = listenerToken.data.token;
      const url = state.wss_stream_url
        ? state.wss_stream_url
        : "wss://livekit-test.muaysod.com";
      // console.log("WEBRTC", state.room.connect(url, token, roomConnectOpt));
      try {
        await state.room.connect(url, token, roomConnectOpt);
      } catch (error) {
        // context.emit("isLoading", false);
        console.log("error----> ", error);
      }
    };
    const renderParticipant = (participant) => {
      const streamerIdentity = `L${state.price.league_id}`;
      const dealerIdentity = `L${state.price.league_id}:GP${route.params.id}`;
      //streamer : Camera + Microphone
      if (streamerIdentity === participant.identity) {
        const cameraPub = participant.getTrack(Track.Source.Camera);
        const micPub = participant.getTrack(Track.Source.Microphone);
        const videoElm = document.querySelector(".vp-container > video");
        const audioELm = document.getElementById("streamListener");
        console.log(videoElm);

        console.log(`participant = ${participant.identity}`);
        if (cameraPub !== undefined && cameraPub.videoTrack !== undefined) {
          console.log(`cameraPub = ${cameraPub}`);
          console.log(`attach participant ${participant.identity} to video`);
          cameraPub.videoTrack.attach(videoElm);
        }

        if (micPub !== undefined && micPub.audioTrack !== undefined) {
          console.log(`micPub = ${micPub}`);
          console.log(`attach participant ${participant.identity} to audio`);
          if (!state.attachedStreamListener) {
            console.log(
              `attach participant ${participant.identity} to audio as streamer`
            );
            state.attachedStreamListener = true;
            micPub.audioTrack.attach(audioELm);
          }
        }
      }
      //dealer : Microphone
      else if (dealerIdentity === participant.identity) {
        const micPub = participant.getTrack(Track.Source.Microphone);
        const audioELm = document.getElementById("dealerListener");
        if (micPub !== undefined && micPub.audioTrack !== undefined) {
          if (!state.attachedDealerListener) {
            console.log(
              `attach participant ${participant.identity} to audio as dealer`
            );
            state.attachedStreamListener = true;
            micPub.audioTrack.attach(audioELm);
          }
        }
      }
    };
    const handleClickPlay = (event) => {
      const element = document.querySelector(".live-div > video");
      state.isPlay = event;
      if (state.isPlay === true) {
        element.play();
      } else {
        element.pause();
      }
    };
    const stopStream = () => {
      try {
        state.room.disconnect(true);
        console.log("disconect audio");
      } catch {
        console.log("error Audio");
      }
    };
    return {
      ...toRefs(state),
      handleClickPlay,
      handleClickConfirmVideoId,
    };
  },
};
</script>

<style lang="scss" scoped>
.vp-container {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  max-width: 600px;
  width: calc(25vw);
  height: auto;
  font-family: "JamjureeSemibold";
  background-color: #3e3e3e;
  color: white;
  border-radius: 1rem;
}
.live-btn {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  color: white;
  margin-right: 0;
  & .hover-pause {
    opacity: 0;
  }
}
.live-div:hover {
  & .hover-pause {
    opacity: 1;
  }
}
</style>
