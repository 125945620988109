<template>
  <div
    v-if="
      isTable === true &&
      Object.keys(roundPrice).length > 0 &&
      roundPrice.round_prices.length > 0 &&
      roundPrice.round_id === priceInfo.round_id
    "
    class="rp-container"
  >
    <div class="p-3 h-100">
      <div class="row mx-0">
        <div class="col ps-0">
          <button
            @click="handleClickSelectSide('red')"
            class="btn btn-red"
            :class="[
              isSelectSide === 'blue' ? 'notSelect' : '',
              priceInfo.is_pause === true ? 'cross' : '',
            ]"
            :disabled="priceInfo.is_pause === true ? true : false"
          >
            ต่อแดง
            <span v-if="isSelectSide === 'red'">
              <i class="fas fa-check-circle"></i>
            </span>
          </button>
        </div>
        <div class="col pe-0">
          <button
            @click="handleClickSelectSide('blue')"
            class="btn btn-blue"
            :class="[
              isSelectSide === 'red' ? 'notSelect' : '',
              priceInfo.is_pause === true ? 'cross' : '',
            ]"
            :disabled="priceInfo.is_pause === true ? true : false"
          >
            ต่อน้ำเงิน
            <span v-if="isSelectSide === 'blue'">
              <i class="fas fa-check-circle"></i>
            </span>
          </button>
        </div>
      </div>
      <div
        v-if="price.mode === 'auto_s'"
        @click="
          $emit(
            'handleClickChangePrice',
            checkPrice(priceInfo.home_price).indexOf('ต') !== -1
              ? 'red'
              : 'blue',
            suggestPrice
          )
        "
        class="auto_s-price text-black cursor-pointer"
        :class="[
          priceInfo.last_change_price === null ||
          priceInfo.last_change_price === false
            ? 'show my-3 p-2'
            : '',
        ]"
        data-bs-toggle="modal"
        :data-bs-target="
          timeLeft > 0
            ? '#delayPrice'
            : price.home_price !== price.away_price
            ? '#confirmModal'
            : '#drawModal'
        "
      >
        <div
          v-show="
            priceInfo.last_change_price === null ||
            priceInfo.last_change_price === false
          "
        >
          <div class="text-center">ราคาแนะนำ</div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="red text-center">
              {{ checkPrice(priceInfo.home_price) }}
            </div>
            <div class="blue text-center">
              {{ checkPrice(priceInfo.away_price) }}
            </div>
          </div>
        </div>
      </div>
      <div class="row text-center border-top border-bottom mx-0 mt-1">
        <div class="col-4 border-end" style="width: calc(33.33333333% - 3px)">
          <div class="my-2">ชื่อ</div>
        </div>
        <div class="col-8">
          <div class="my-2">
            <span>ราคา </span>
            <span class="text-warning">( {{ roundPrice.round_name }} )</span>
          </div>
        </div>
      </div>
      <div
        class="body-h"
        :style="
          price.mode === 'auto_s'
            ? priceInfo.last_change_price === null ||
              priceInfo.last_change_price === false
              ? 'height: 75%'
              : 'height: 87.7%'
            : 'height: 87.7%'
        "
      >
        <!-- <table class="table table-bordered">
          <tbody>
            <tr v-for="(price, index) in roundPrice.round_prices" :key="index">
              <th scope="row">{{ price.name }}</th>
              <td>{{ price.home_price }}</td>
              <td>{{ price.away_price }}</td>
            </tr>
          </tbody>
        </table> -->
        <!-- v-if="price.name !== 'ตร'" -->
        <div
          v-for="(price, index) in roundPrice.round_prices"
          :key="index"
          @click="$emit('handleClickChangePrice', side, price.name)"
        >
          <!-- && !/[a-zA-Z]/g.test(price.name) -->
          <div
            class="row border-bottom mx-0 price-table cursor-pointer"
            :class="[
              isSelectSide === 'red'
                ? 'red'
                : isSelectSide === 'blue'
                ? 'blue'
                : '',
              price.name === 'ตร' &&
              priceInfo.is_price_open === true &&
              priceInfo.bargain !== '' &&
              priceInfo.home_price.split('/').reverse().join('/') ===
                price.home_price &&
              priceInfo.away_price === price.away_price &&
              priceInfo.home_price_limit === parseInt(price.home_amount) &&
              priceInfo.away_price_limit === parseInt(price.away_amount)
                ? 'active-yellow'
                : priceInfo.is_price_open === true &&
                  priceInfo.bargain !== '' &&
                  priceInfo.bargain === 'home' &&
                  priceInfo.home_price.split('/').reverse().join('/') ===
                    price.home_price &&
                  priceInfo.away_price === price.away_price &&
                  priceInfo.home_price_limit === parseInt(price.home_amount) &&
                  priceInfo.away_price_limit === parseInt(price.away_amount)
                ? 'active-red'
                : priceInfo.is_price_open === true &&
                  priceInfo.bargain !== '' &&
                  priceInfo.bargain === 'away' &&
                  priceInfo.home_price === price.away_price &&
                  priceInfo.away_price.split('/').reverse().join('/') ===
                    price.home_price &&
                  priceInfo.home_price_limit === parseInt(price.away_amount) &&
                  priceInfo.away_price_limit === parseInt(price.home_amount)
                ? 'active-blue'
                : '',
            ]"
            :data-bs-toggle="isSelectSide !== '' ? 'modal' : ''"
            :data-bs-target="
              timeLeft > 0
                ? '#delayPrice'
                : price.name !== 'ตร'
                ? '#confirmModal'
                : '#drawModal'
            "
          >
            <div class="col-4 border-end text-center position-relative">
              <div
                v-if="
                  (priceInfo.bargain !== '' &&
                    priceInfo.bargain === 'home' &&
                    priceInfo.home_price.split('/').reverse().join('/') ===
                      price.home_price &&
                    priceInfo.away_price === price.away_price &&
                    priceInfo.home_price_limit ===
                      parseInt(price.home_amount) &&
                    priceInfo.away_price_limit ===
                      parseInt(price.away_amount)) ||
                  (priceInfo.bargain !== '' &&
                    priceInfo.bargain === 'away' &&
                    priceInfo.home_price === price.away_price &&
                    priceInfo.away_price.split('/').reverse().join('/') ===
                      price.home_price &&
                    priceInfo.home_price_limit ===
                      parseInt(price.away_amount) &&
                    priceInfo.away_price_limit === parseInt(price.home_amount))
                "
                class="marks"
                :class="
                  priceInfo.bargain !== '' &&
                  priceInfo.bargain === 'home' &&
                  priceInfo.home_price.split('/').reverse().join('/') ===
                    price.home_price &&
                  priceInfo.away_price === price.away_price &&
                  priceInfo.home_price_limit === parseInt(price.home_amount) &&
                  priceInfo.away_price_limit === parseInt(price.away_amount)
                    ? 'red'
                    : priceInfo.bargain !== '' &&
                      priceInfo.bargain === 'away' &&
                      priceInfo.home_price === price.away_price &&
                      priceInfo.away_price.split('/').reverse().join('/') ===
                        price.home_price &&
                      priceInfo.home_price_limit ===
                        parseInt(price.away_amount) &&
                      priceInfo.away_price_limit === parseInt(price.home_amount)
                    ? 'blue'
                    : ''
                "
              >
                >
              </div>
              <div class="my-1">{{ price.name }}</div>
            </div>
            <div class="col border-end text-center">
              <div class="my-1">{{ price.home_price }}</div>
            </div>
            <div class="col text-center">
              <div class="my-1">{{ price.away_price }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { utilily } from "../utilities/utility";

export default {
  props: ["timeLeft", "isTable"],
  setup(props) {
    const store = useStore();
    const { isEmpty } = utilily();
    const state = reactive({
      roundPrice: computed(() => store.state.priceModule.roundPrice),
      priceInfo: computed(() => store.state.priceModule.priceInfo),
      price: computed(() => store.state.priceModule.price),
      suggestPrice: null,
      isSelectSide: "",
      side: "",
      timeLeft: props.timeLeft,
      isTable: props.isTable,
    });
    onMounted(() => {
      clearInterval(window.priceIntv);
      // state.suggestPrice = checkSuggestPrice();
      // console.log(state.suggestPrice);
      window.priceIntv = setInterval(() => {
        const elements = document.getElementsByClassName("price-table");
        if (elements.length > 0) {
          clearInterval(window.priceIntv);
          for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener("click", function () {
              state.isSelectSide = "";
            });
          }
        }
      }, 500);
    });
    watch(
      () => [state.price, state.roundPrice],
      ([price, roundPrice]) => {
        if (!isEmpty(price) && !isEmpty(roundPrice)) {
          state.suggestPrice = checkSuggestPrice();
          console.log("suggestPrice", state.suggestPrice);
        }
      }
    );
    // const handleClickPrice = () => {
    //   console.log("ASDSAD");
    // };
    const handleClickSelectSide = (event) => {
      state.isSelectSide = event;
      state.side = event;
    };
    // const handleClickSetNumber = (event) => {
    //   state.set = event;
    // };
    const checkSuggestPrice = () => {
      if (state.price.home_price === null && state.price.away_price === null) {
        return;
      }
      for (
        let index = 0;
        index < state.roundPrice.round_prices.length;
        index++
      ) {
        const element = state.roundPrice.round_prices[index];
        if (checkPrice(state.price.home_price).indexOf("ต") !== -1) {
          if (
            checkPrice(
              state.price.home_price,
              state.price.away_price,
              "home"
            ) === element.home_price &&
            checkPrice(
              state.price.away_price,
              state.price.home_price,
              "away"
            ) === element.away_price
          ) {
            return element.name;
          }
        } else {
          if (
            checkPrice(
              state.price.home_price,
              state.price.away_price,
              "home"
            ) === element.away_price &&
            checkPrice(
              state.price.away_price,
              state.price.home_price,
              "away"
            ) === element.home_price
          ) {
            return element.name;
          }
        }
      }
    };
    const reverseString = (string) => {
      return string.split("/").reverse().join("/");
    };
    const checkPrice = (string1, string2 = null, side = null) => {
      if (string1 === null && string2 === null) {
        return;
      }
      const Arr1 = string1.split("/");
      if (string2 !== null) {
        if (string1 === string2) {
          if (side === "home") {
            return reverseString(string1);
          } else {
            return string1;
          }
        }
        if (Arr1[1] / Arr1[0] <= 1) {
          // ต่อ
          return `${string1}`;
        } else {
          // รอง
          return `${reverseString(string1)}`;
        }
      } else {
        if (Arr1[1] / Arr1[0] <= 1) {
          // ต่อ
          return `ร${string1}`;
        } else {
          // รอง
          return `ต${reverseString(string1)}`;
        }
      }
    };
    return {
      ...toRefs(state),
      handleClickSelectSide,
      checkPrice,
      checkSuggestPrice,
    };
  },
};
</script>

<style lang="scss" scoped>
.marks {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  &.red {
    color: #ff6c72;
  }
  &.blue {
    color: #3271ff;
  }
}
.btn-set {
  width: 100%;
  font-family: "JamjureeSemibold";
  font-size: 1.075rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #666666;
  color: black;
  &:hover {
    color: black;
  }
  &.active {
    background-color: #ffe248;
    color: black;
  }
  &:disabled {
    opacity: 0.4;
    background-color: gray;
  }
}
.rp-container {
  position: absolute;
  top: 0;
  left: 105%;
  height: 100%;
  max-width: 50%;
  width: 25vw;
  font-family: "JamjureeSemibold";
  background-color: #3e3e3e;
  color: white;
  border-radius: 1rem;
}

// .btn-h {
//   height: 6%;
// }
// .head-h {
//   height: 5.5%;
// }
.body-h {
  // height: 90%;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  overflow-y: scroll;
  min-height: 0%;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #cfd8dc;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #90a4ae;
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.mx-025 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
</style>
